/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;1,600&display=swap");

@import "https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap";

// Functions first
@import "../node_modules/bootstrap/scss/functions";

@import "./assets/icons/custom-icons/style.css";
@import "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css";
@import "assets/scss/custom-grid";

$primary: #3247c6;
$secondary: #6c757d;
$success: #008b06;
$dark: #212529;
$danger: #ff798b;
$light: #f8f9fa;
$warning: #ffc107;
$info: #0dcaf0;
$ternary: #e54f6d;
$white: #ffffff;
$judge-ternary: #e54f6d;
$judge-primary: #0b66e4;

$body-color: #222222;

$border-color: #f9f9f9;
$enable-shadows: true;
$box-shadow: 0 0 0.25rem rgba(#e2ecf9, 0.45);
$btn-box-shadow: none;
// $card-border-color: #dee2e6;
$card-border-color: #f9f9f9;
$card-box-shadow: $box-shadow;

$accordion-button-active-bg: #ffffff;
$accordion-button-bg: #f6f6f6;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "ternary": $ternary,
  "white": $white,
  "judge-ternary": $judge-ternary,
  "judge-primary": $judge-primary,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxlg: 1920px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxlg: 1800px,
);

// Custom style variables
$avatar-size: 40px;
$page-container-shadow: $box-shadow;
$custom-toggle-width: 120px;

// CSS Root Variable
:root {
  --custom-toggle-width: #{$custom-toggle-width};
  --swiper-navigation-color: #ffffff;
  --dashboard-graph-backgorund: #fff7f9;
  --dashboard-graph-line-color: #e54f6d;
  --form-check-border-color: #e0e0e0;
}

// Required Bootstrap imports
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/variables-dark";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// Optional components
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";

@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.css";
@import "./app/common-styles/components/styles.scss";

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #fbfcfe;
  color: $body-color;
}

.font-sm {
  font-size: 12px;
}

.font-md {
  font-size: 14px;
}

ul {
  padding-left: 0;
}

.overflow-vertical {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.overflow-horizontal {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

iframe {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
}

#card-element {
  iframe {
    max-height: none !important;
  }
}

.flex-wrap-items {
  flex-wrap: wrap !important;
}

.newDocDialog {
  .p-dialog-header {
    background: $darkblue1 !important;
    color: white !important;
    padding: 15px;
  }
  .p-dialog-content {
    padding-top: 15px;
  }

  .p-dialog-header-close-icon {
    color: white !important;
  }

  .p-dialog-header-icons {
    border-radius: 25px !important;
  }

  .p-dialog-header .p-dialog-title {
    width: 100%;
  }
}

.table-container-scroll {
  width: 100%;
  overflow: auto;
}

.form-check-input {
  border-color: var(--form-check-border-color);
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 8px;
  height: 5px;
  @media screen and (max-width: 768px) {
    height: 0;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: $lightgrey1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 20px;
  width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $darkblue1;
}

// Mobile View Css
@media (max-width: 580px) {
  .p-toast {
    width: 20rem !important;
  }
  .steps {
    .flex {
      display: block !important;
      margin: 5px 0;
    }
  }
  .notification-dropdown {
    ul {
      width: 300px !important;
      right: -50px !important;
    }
  }
  .request-list {
    .flex {
      display: block !important;
    }
  }
  .login-container {
    .card-container {
      width: 100% !important;
      .card-padding {
        padding: 30px 10px !important;
      }
    }
  }
  .information-list {
    label {
      width: 120px;
    }
    &.block {
      li {
        display: block !important;
        span {
          display: block !important;
        }
        label {
          display: block !important;
        }
      }
    }
  }
  .grid-2 {
    grid-template-columns: 1fr !important;
  }
  .header-container {
    display: block !important;
  }
  .request-info,
  .sat-score {
    display: grid !important;
    .p-divider {
      display: block;
      border: 0.2px solid $primary;
      height: 1px;
      padding: 0;
      margin: -10px 0 0 0;
    }
  }
  .grid-headers {
    display: grid !important;
  }
  .full-width .p-selectbutton {
    display: block;
  }
}

@media (max-width: 1024px) {
  .question-lists-items ul {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  }
  .p-menubar-button {
    position: absolute;
    right: 20px;
  }

  .masters-subject-container-items,
  .templates-documents-container,
  .grid-3,
  .student-profile-container,
  .grid-question-items,
  .academic-grid,
  .platform-dashboard-container,
  .grid-templates-university,
  .student-test-list,
  .new-request-popup-container {
    grid-template-columns: 1fr !important;
  }
  .grid-question-items {
    position: relative !important;
  }
  .grid-item {
    grid-template-columns: 2fr 10fr !important;
  }
  .p-card-style {
    width: 20px !important;
    height: 30px !important;
    position: absolute !important;
    z-index: 10 !important;
    top: -10px !important;
    right: -6px !important;
  }
  .grid-question-items button {
    display: block !important;
    text-align: center !important;
  }
  .grid-container {
    .sidebar {
      position: fixed !important;
      left: -250px !important;
    }
  }
  .side-menu {
    // display: none;
    margin-bottom: 20px;
    img {
      right: 20px !important;
      left: auto !important;
      top: 20px !important;
    }
  }
  .show {
    display: block !important;
    width: 100% !important;
  }
  .hide {
    display: none !important;
  }
  .modal-content {
    display: block !important;
  }
  .fixed {
    &::after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $opacityoneblack;
      z-index: 5;
    }
    .sidebar {
      position: fixed !important;
      left: 0 !important;
      top: 0;
      bottom: 0;
      background: $white;
      z-index: 6;
      box-shadow: 6px 1px 10px $opacityoneblack;
      opacity: 1;
      transition: 0.5s;
      transform: translateX(0);
      overflow: auto;
    }
    &.not-fixed {
      display: none;
      opacity: 0;
      transition: 0.5s;
      transform: translateX(-250px);
    }
  }
  header {
    display: block !important;
  }
}

@media (min-width: 581px) and (max-width: 1024px) {
  .login-container {
    .card-container {
      width: 80% !important;
      .card-padding {
        padding: 30px 10px !important;
      }
    }
  }
}

// End tablet View Css

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
}

html {
  padding: 0;
  margin: 0;
}

.p-component {
  font-family: "Poppins";
}

.admin-body-container {
  padding: 16px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 42px;
  margin-bottom: 20px;
  h3 {
    color: #292d32;
    font-weight: 500;
    font-size: 24px;
    margin: 0;
  }
  p {
    color: rgba(41, 45, 50, 0.6);
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    margin-top: 0;
  }
}

.sub-header {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 37px;
  text-transform: capitalize;
  color: $black;
  margin-bottom: 20px;
  margin-top: 0;
}

.margin-0 {
  margin: 0;
}

.margin-minus-15 {
  margin: 0 -15px;
}

.sub-headers {
  h3 {
    color: $black;
    font-size: 20px;
    margin: 0px 0 10px 0;
    padding: 0;
    font-family: "Cairo";

    width: fit-content;
    &::after {
      content: "";
      background: linear-gradient(
        90deg,
        rgba(65, 84, 202, 1) 0%,
        rgba(65, 84, 202, 0.8) 80%,
        rgba(65, 84, 202, 0) 100%
      );
      opacity: 0.5;
      display: block;
      height: 2px;
      background-repeat: no-repeat;
      margin-top: 10px;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.flex-without-justify {
  display: flex;
  align-items: center;
  gap: 10px;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.flex-1 {
  flex: 1;
  width: 100%;
}

.flex-half {
  flex: 0.5;
}

.information-list {
  &.border {
    li {
      padding: 15px 0 !important;
      border-bottom: 1px solid $lightgrey5;
      &:last-child {
        border: none;
      }
    }
  }
  &.left-align {
    justify-content: left !important;
    li {
      span {
        flex: 1;
      }
      label {
        flex: 4;
      }
    }
  }
  &.full-width {
    width: 100%;
    li {
      span {
      }
      label {
        flex: none;
      }
    }
  }
  &.remove-margin {
    margin: 0;
  }
  margin: 0 0 20px 0;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    gap: 20px;
    button {
      span {
        color: $white;
        opacity: 1;
      }
    }
    span {
      color: $darkgrey2;
      opacity: 0.6;
      word-break: break-all;
      flex: 1;
      z-index: 2;
    }
    label {
      color: $darkgrey2;
      font-size: 18px;
      font-weight: 400;
      word-break: break-all;
      flex: 2;
      z-index: 2;
    }
  }
}

.floating-icon-bottom {
  position: absolute;
  bottom: 0;
  right: 10px;
  z-index: 1;
}

.without-p-card {
  padding: 20px 0;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-transform: capitalize;
    color: $darkgrey5;
    small {
      font-size: 20px;
      color: $lightgrey3;
      font-weight: 400;
    }
  }
}

.input-group {
  margin-bottom: 20px;
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    color: $black;
    opacity: 0.7;
    display: block;
    margin-bottom: 10px;
  }
}

.sub-text {
  margin: 0;
  color: #3c4852;
  font-size: 14px;
  line-height: 25px;
}

.full-width {
  display: block;
  width: 100%;
}

.column-listing {
  li {
    border-bottom: 1px solid $lightgrey2;
    padding: 30px 0px;
  }
}

.info-container {
  .info-list {
    margin-top: 20px;
    h4 {
      color: $primary;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
}

.in-review {
  background: $lightyellow;
  border: 1px solid $darkyellow;
  border-radius: 8px;
  width: 150px;
  padding: 20px;
  text-align: center;
  display: grid;
  align-items: center;
  z-index: 5;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    margin: 0;
    color: $yellow;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: $yellow;
  }
}

.finished {
  background: $lightgreen1;
  border: 1px solid $darkgreen;
  border-radius: 8px;
  width: 150px;
  padding: 20px;
  text-align: center;
  display: grid;
  align-items: center;
  z-index: 5;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    margin: 0;
    color: $green1;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: $green1;
  }
}

.cancelled {
  background: $lightred1;
  border: 1px solid $darkred1;
  border-radius: 8px;
  width: 150px;
  padding: 20px;
  text-align: center;
  display: grid;
  align-items: center;
  z-index: 5;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 62px;
    margin: 0;
    color: $red;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
    color: $red;
  }
}

.red {
  color: $red !important;
}

.green {
  color: $green !important;
}

.blue {
  color: $primary !important;
}

.yellow {
  color: $yellow !important;
}

.margin-b-20 {
  margin-bottom: 20px;
  display: block;
}

.student-info-container {
  .flex {
    p {
      margin: 0;
    }
  }
}

.switch {
  position: relative;
  line-height: 40px;
  span {
    padding: 0 20px;
    display: block;
    font-size: 15px;
    text-align: center;
    i {
      margin-right: 5px;
    }
  }
  &.full-width {
    width: 100% !important;
    input:checked + .slider:before {
      transform: translateX(97%) !important;
    }
  }
  &.black-color {
    .slider {
      border: 1px solid $lightgrey3 !important;
      &.slider:before {
        background: $lightgrey4 !important;
      }
    }
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  input:checked + .slider span:first-child {
    color: $black;
  }

  input:checked + .slider span:nth-child(2) {
    color: $white;
  }

  input + .slider span:first-child {
    color: $white;
  }

  input + .slider span:nth-child(2) {
    color: $black;
  }

  input:checked + .slider:before {
    transform: translateX(95%);
  }
  .on {
    z-index: 1;
    color: $black;
  }
  .off {
    z-index: 1;
    color: $black;
  }
  .slider {
    background: $white;
    border: 1px solid $primary;
    box-sizing: border-box;
    border-radius: 6px;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    grid-template-columns: 1fr 1fr;
    &.slider:before {
      position: absolute;
      content: "";
      height: 36px;
      width: 50%;
      left: 3px;
      bottom: 4px;
      background: $primary;
      border-radius: 4px;
      transition: 0.4s;
    }
  }
}

header {
  display: none;
}

.flex-end-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin: 20px 0px;
}

.box-hover {
  background: $white;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: 0.4s all;
  &:hover {
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  }
}

.textarea-glowing {
  position: relative;
  margin: 20px 0;
  .sub-text {
    padding: 10px;
  }
  textarea {
    width: 100%;
    padding: 10px;
    border: none;
  }
  button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  background: $white;
  border-radius: 10px;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.1);
}

.justify-center {
  justify-content: center;
}

.border-box {
  border: 1px solid $border-color;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
}

.student-header-part {
  h4 {
    color: #30313d;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    margin: 10px 0 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.p-menubar {
  background: $white !important;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.05) !important;
  border: none;
  border-radius: 0;
}

.p-menubar-start {
  img {
    width: 100px;
    margin-right: 60px;
    margin-left: 20px;

    @media screen and (max-width: 400px) {
      margin-right: 20px;
    }
  }
}

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text,
.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-submenu-icon {
  color: $black;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  margin-right: 10px;
}

// .p-menubar-root-list li:last-child {
//   position: absolute;
//   right: 0;
// }

.uk-icon {
  background: url("./assets/icons/uk.svg");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.workbook-icon {
  background: url("./assets/icons/workbook-icon.svg");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.us-icon {
  background: url("./assets/icons/us.svg");
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.p-menubar {
  .p-menuitem-link-active {
    span {
      color: $primary !important;
    }
  }
}

.p-menubar
  .p-submenu-list
  > .p-menuitem-active
  > p-menubarsub
  > .p-submenu-list {
  display: block;
  left: 0 !important;
  top: 0;
  position: relative !important;
  box-shadow: none;
}

.p-menubar {
  height: 50px;
}

.judges-portal-header {
  .p-menubar {
    padding: 0.725rem;
    height: auto;

    .p-menubar-start img {
      margin-left: 0;
    }
  }
}

app-upcoming-events .p-calendar .p-inputtext {
  display: none;
}

app-upcoming-events .p-calendar .p-datepicker {
  top: 40px !important;
  left: -300px !important;
  width: 400px !important;
}

@media (max-width: 600px) {
  app-upcoming-events .p-calendar .p-datepicker {
    left: -355px !important;
  }

  .p-paginator .p-paginator-current {
    min-width: 100% !important;
    justify-content: center !important;
  }

  .newDocDialog {
    .p-dialog-header {
      align-items: flex-start !important;
      height: 130px !important;
      text-align: center !important;
    }
    .listCredits {
      top: 50px !important;
      font-size: 15px !important;
      width: 100% !important;
      text-align: center !important;
    }

    .headerBtns {
      top: 83px !important;
    }
  }
}

app-essays .p-tabmenu-nav {
  display: flex;
  justify-content: space-evenly;
}

app-essays
  .default-tabmenu
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22.5px;
}
app-essays
  .default-tabmenu
  .p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem.p-highlight
  .p-menuitem-link {
  border-bottom: 3px solid $ternary !important;
  color: $ternary !important;
}

@media (min-width: 950px) {
  .right-submenu {
    float: right;
    position: absolute !important;
    right: 0;
    width: 150px;
    a {
      span {
        &.p-menuitem-text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .right-submenu ul {
    right: 10px;
  }
}

.side-menu-container {
  .p-menuitem-link-active {
    background-color: #3247c6;
    .p-menuitem-icon,
    .p-menuitem-text {
      color: $white !important;
      filter: brightness(100);
    }
  }
  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: #292e4d;
    &:hover {
      color: $white;
    }
  }
  .p-menuitem-text {
    font-weight: 400;
    font-family: "Poppins";
  }
}

.icon-workbook {
  background: url(./assets/icons/User_UI/workbook.svg);
  width: 20px;
  height: 20px;
}

.icon-diary {
  background: url(./assets/icons/User_UI/diary.svg);
  width: 20px;
  height: 20px;
}

.icon-modules {
  background: url(./assets/icons/User_UI/modules.svg);
  width: 20px;
  height: 20px;
}

.icon-qanda {
  background: url(./assets/icons/User_UI/q&a.svg);
  width: 20px;
  height: 20px;
}

.icon-question-bank {
  background: url(./assets/icons/User_UI/question-bank.svg);
  width: 20px;
  height: 20px;
}

.icon-upcoming-calendar {
  background: url(./assets/icons/User_UI/upcoming-calendar.svg);
  width: 20px;
  height: 20px;
}

.icon-your-requests {
  background: url(./assets/icons/User_UI/your-requests.svg);
  width: 20px;
  height: 20px;
}

.icon-personality-test {
  background: url(./assets/icons/User_UI/personality-test.svg);
  width: 20px;
  height: 20px;
}

.icon-academic-record {
  background: url(./assets/icons/User_UI/academic-record.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-standardized-test {
  background: url(./assets/icons/User_UI/standardized-test.svg);
  width: 20px;
  background-repeat: no-repeat;
  height: 20px;
}

.icon-academic-interest {
  background: url(./assets/icons/User_UI/academic-interest.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-career-interest {
  background: url(./assets/icons/User_UI/career-interest.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-university-plans {
  background: url(./assets/icons/User_UI/university-plans.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-extracurricular-activities {
  background: url(./assets/icons/User_UI/extracurricular-activities.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-supercur-activity {
  background: url(./assets/icons/User_UI/icon-supercur-activity.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-univ-subject {
  background: url(./assets/icons/User_UI/icon-univ-subject.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}

.icon-lock {
  background: url(./assets/icons/icon-lock.svg);
  width: 20px;
  height: 22px;
  background-repeat: no-repeat;
  display: inline-block;
}

// .seach-tool-container-body {
//   .p-selectbutton {
//     margin: 0 !important;
//   }
// }

// .search-tool-container {
//   .p-card {
//     margin-bottom: 20px !important;
//   }
// }

.pricing-header {
  .p-card {
    overflow: inherit !important;
  }
}

app-headers .p-menubar .p-menuitem-text {
  line-height: 1.7;
}

.right-submenu-main {
  position: absolute !important;
  right: 110px !important;
  background: linear-gradient(180deg, #ff7691 0%, $ternary 100%);
  border-radius: 16px;
  span {
    color: $white !important;
    line-height: normal !important;
  }
}

.right-submenu-main-two {
  position: absolute !important;
  right: 0 !important;
  border-radius: 16px;
  border: 1px solid $ternary;
  span {
    color: $ternary !important;
    line-height: normal !important;
  }
}

// app-search-tool .subjectsPopup .p-dialog-content,
// app-search-tool .majorsPopup .p-dialog-content {
//   overflow: hidden !important;
// }

@media (max-width: 480px) {
  .right-submenu-main-two,
  .right-submenu-main {
    position: relative !important;
    left: 0;
    margin: 3px;
    right: 0px;
    width: 90% !important;
  }
}

.card-container {
  .p-dropdown {
    width: 100% !important;
  }
}

.p-tabmenu-nav-btn {
  z-index: 1 !important;
}

.grid-area-notification {
  display: grid;
  grid-template-columns: 50px 2fr;
  align-items: center;
  gap: 10px;
}

.subscription-plan-container {
  .pricing-info-body {
    min-height: 200px;
  }
}

.platform-dashboard-container {
  .p-card-body {
    //   height:440px;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.right-submenu-main,
.right-submenu-main-two {
  a {
    margin: 0 !important;
  }
}

.workbook-icon {
  background: url("./assets/icons/workbook-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.dashboard-icon {
  background: url("./assets/icons/dashboard-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.modules-icon {
  background: url("./assets/icons/modules-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.question-icon {
  background: url("./assets/icons/question-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.question-bank-icon {
  background: url("./assets/icons/question-bank-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.request-icon {
  background: url("./assets/icons/request-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.diary-icon {
  background: url("./assets/icons/diary-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 1px;
}

.gap0 {
  gap: 0px !important;
}

.gap10 {
  gap: 10px !important;
}

.gap20 {
  gap: 20px !important;
}
.gap30 {
  gap: 30px !important;
}
.gap40 {
  gap: 40px !important;
}
.gap50 {
  gap: 50px !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.text-muted {
  color: #6c757d !important;
}

// .text-center {
//   text-align: center !important;
// }

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.lock-red {
  .p-menuitem-text {
    color: #ff0000 !important;
  }
}
.fullscreen-modal {
  max-height: 100% !important;
  width: 100% !important;
}
.fullscreen-modal .p-dialog-content {
  height: 100% !important;
}

.fullscreen-modal .p-dialog-header {
  padding: 0 !important;
}

.h-100 {
  height: 100% !important;
}

// new
.programme-work {
  .p-tabview-nav-container {
    display: none;
  }
  .p-tabview .p-tabview-panels {
    background: none;
  }
}

.review-items {
  .p-carousel-prev,
  .p-carousel-next {
    background: linear-gradient(180deg, #fb6d89 0%, $ternary 100%) !important;
    border: 1px solid #f16a85 !important;
    box-shadow: 2px 3px 28px rgba(229, 79, 109, 0.34) !important;
    border-radius: 60px !important;
    color: #fff !important;
  }
}

.msgCard {
  margin-bottom: 20px;
}

.customTabView {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  //onboard our-program

  .p-tabview-panels {
    padding: 0 !important;
  }
}

.transparentPopupWithClose {
  .p-dialog-header-icons {
    background: rgba(227, 0, 0, 1) !important;
    z-index: 999;
  }
  box-shadow: none !important;
  .p-dialog-header {
    background: transparent !important;
  }

  .p-dialog-content {
    background: transparent !important;
  }
  .p-dialog-header-icons span {
    color: white !important;
  }

  iframe {
    border-radius: 20px !important;
    min-height: 500px;
  }
}

.fill-grayscale {
  filter: grayscale(100%) !important;
}

app-onboard .p-tabmenu-nav-content {
  background: #ffffff !important;
}

app-onboard .p-tabmenu-nav {
  overflow-x: auto !important;
}

app-onboard .p-tabmenu-nav::-webkit-scrollbar {
  height: 1px !important;
}

.dropdown-h-100 {
  height: 100% !important;
}

.notLoggedInHeaderBtn {
  background: white !important;
  span {
    color: black !important;
  }
}

.notLoggedInHeaderBtnTwo {
  background: linear-gradient(180deg, #ff7691 0%, $ternary 100%) !important;
  border-radius: 16px !important;
  span {
    color: white !important;
  }
}

.p-menubar .p-submenu-list {
  z-index: 3 !important;
}

.toggle-wrapper {
  &.student-forum {
    @media screen and (min-width: 992px) {
      display: none;
    }
    label {
      span {
        top: 8px;
        text-align: center;
        width: calc(var(--custom-toggle-width) / 2 - 10px);

        &:nth-child(1) {
          left: 4px;
        }
        &:nth-child(2) {
          right: 4px;
        }
      }
      &:after {
        top: 5px;
      }
    }
  }
  label {
    cursor: pointer;
    width: var(--custom-toggle-width);
    height: 40px;
    background: #fdf6f7;
    display: block;
    border-radius: 50rem;
    position: relative;
    box-shadow: inset rgba($color: #000000, $alpha: 0.1) 0px 0px 5px 0px;
    // border: 1px solid #d9d9d9;

    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: calc((var(--custom-toggle-width) / 2) - 10px);
      height: 30px;
      background: #e74e6d;
      border-radius: 90px;
      transition: 0.3s;
      box-shadow: inset rgba($color: #000000, $alpha: 0.28) 0px 0px 5px 0px;
    }

    span {
      color: #292d32;
      position: absolute;
      top: 8px;
      z-index: 1;
      transition: 0.3s;
      &:nth-child(1) {
        left: 18px;
        color: #ffffff;
      }
      &:nth-child(2) {
        right: 18px;
      }
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      & + label {
        span {
          &:nth-child(2) {
            color: #ffffff;
          }
          &:nth-child(1) {
            color: #292d32;
          }
        }
        &:after {
          left: calc(100% - 5px);
          transform: translateX(-100%);
        }
      }
    }
  }
  &.toggle-university {
    label {
      width: 380px;
      background: #fafafa;
      &:after {
        width: 185px;
        background: $white;
        box-shadow: none;
        height: 38px;
        top: 0;
        left: 0;
        border: 1px solid $darkgrey6;
      }

      span {
        color: #292d32;
        font-size: 14px;
        top: 10px;

        &:nth-child(1) {
          color: $primary;
        }
      }
    }

    input[type="checkbox"] {
      &:checked {
        & + label {
          &::after {
            left: 100%;
          }
          span {
            &:nth-child(2) {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.side-menu-container {
  flex: 0 0 280px;
  max-width: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  // background-color: white;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 9;
    max-width: 100%;
    width: 88%;
    flex: 0 0 100%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }
}

// Primary Sidebar
.side-menu-container {
  transition: 0.3s all;
  transform: translateX(0%);

  .p-menu-list {
    .p-menuitem {
      .p-menuitem-text {
        white-space: nowrap;
        transform: translateX(0%);
      }
    }
  }
}

// Toggle Primary Sidebar

#primarySidebar {
  display: none;
  &:checked {
    & ~ .side-menu-container {
      transform: translateX(-100%);
      max-width: 0;
      opacity: 0;

      @media screen and (max-width: 768px) {
        transform: translateX(100%);
      }

      .p-menuitem {
        .p-menuitem-text {
          opacity: 0;
          transform: translateX(1000%);
        }
      }
    }
    & ~ .primarySidebar-overlay {
      opacity: 0;
      visibility: hidden;
      z-index: -1;
    }
  }
}

.primarySidebar-overlay {
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.4);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
  transition: 0.4s all;
  z-index: 8;
  @media screen and (min-width: 769px) {
    display: none;
  }
}

a {
  text-decoration: none;
}

app-side-menu {
  .p-menu ul {
    margin-left: 0;
  }
}

.p-carousel-items-container {
  .p-carousel-item {
    max-width: 300px;
  }
}

.p-paginator-page,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
.p-paginator .p-paginator-prev {
  font-size: 13px;
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
}

.p-overlaypanel-content {
  margin: 0;
  padding: 0;
}

.grid-responsive-columns {
  grid-template-columns: repeat(4, minmax(0, 1fr));

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.group-student-container {
  .p-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    .p-card-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .p-card-footer {
      margin-top: auto;
    }
  }
}

.event-number {
  font-size: 14px;
  background-color: #eda34a;
  border-color: #fa9f32;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  width: 56px;
  min-width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-dropdown {
  &.dropdown-toggle {
    position: absolute;
    top: 18px;
  }
}

.custom-dropdown-list {
  z-index: 9;
  position: relative;
  width: 260px;
  margin-left: 12px;
  list-style-type: none;
}

@media screen and (max-width: 768px) {
  .custom-dropdown-list {
    width: 100%;
    margin: 0 !important;
    padding: 10px;
  }
}

.calendar-wrapper {
  height: auto;
  display: flex;
  justify-content: center;
  @media screen and (min-width: 992px) {
    height: 458px;
  }
}

app-forum-list {
  .p-card {
    border-radius: 10px;
    border-color: #f1f1f1;
    box-shadow: $page-container-shadow;
  }

  .p-button.p-button-sm {
    padding: 0.25rem 0.75rem;

    &.p-button-secondary {
      color: #a4a4a4 !important;
      border-radius: 50rem;
      font-weight: 500;
    }
    .pi {
      font-size: 14px;
    }
  }
}

.answer-card {
  .p-card {
    background-color: #f6f6f6;
  }
}

// accordion
.accordion {
  &-header {
    margin: 0;
  }

  &-button {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 1rem;

    &:after {
      margin: 0;
    }
  }
}

// Avatar for Profile images
.avatar {
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 50%;
  overflow: hidden;
  background-color: $primary;
  font-size: $font-size-base;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  flex-shrink: 0;

  &.lg {
    width: calc($avatar-size * 2);
    height: calc($avatar-size * 2);
    font-size: 2rem;

    & ~ div {
      .avatar-label {
        font-size: 18px;
      }

      .avatar-email {
        font-size: 14px;
        color: #54577a;
      }
    }
  }
  &.sm {
    width: calc($avatar-size / 2 + 8px);
    height: calc($avatar-size / 2 + 8px);
    font-size: 1rem;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &-label {
    font-size: $font-size-base;
    color: #1d1d1d;
    font-weight: 500;
    flex-shrink: 0;
  }

  &-email {
    font-size: 12px;
    opacity: 0.5;
    color: #1d1d1d;
  }
}
.btn-update {
  background: $white;
  padding: 0;
  color: $ternary;
  box-shadow: none;
  outline: none;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
    color: $ternary;
  }
}

.btn-filter {
  background-color: #e9f2ff;
  color: #0b66e4;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  .icon {
    font-size: 10px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: darken($color: #e9f2ff, $amount: 1.5%) !important;
    color: darken($color: #0b66e4, $amount: 1.5%) !important;
    border-color: transparent !important;
  }
}

.forum {
  &-question-content {
    container-type: inline-size;
    container-name: forum-question-content;
    .p-card {
      .p-card-body {
        padding: 0;
      }
    }

    @container (width < 480px) {
      .forum-question-card {
        .user-details {
          flex-direction: row;

          .share-btn-wrapper {
            display: block;
            margin-left: auto;
          }
        }

        .forum-question-card-content {
          flex-direction: column;
        }
      }

      .forum-action-footer-btns {
        gap: 1rem !important;
        flex-direction: column-reverse;

        & > div {
          width: 100% !important;
        }

        .share-btn-wrapper {
          display: none;
        }

        .p-button.p-button-sm {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .p-button-label {
            flex: none;
          }
        }
      }
    }
  }

  &-question-card {
    border-bottom: 1px solid #ededed;

    .user-details {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.45rem;
      flex-shrink: 0;
      .forum-username {
        font-size: 12px;
      }
      .share-btn-wrapper {
        display: none;
      }
    }

    p {
      margin: 0;

      &.text-desc {
        color: #a4a4a4;
        font-size: 12px;
      }
    }
  }

  &-action-footer-btns {
    gap: 1rem;
    @media screen and (min-width: 768px) {
      gap: 2rem;
    }
  }
}

.forum-user-country {
  background-color: $ternary;
  color: $white;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 12px;
}

.tips-container {
  height: 100%;
  overflow: hidden;

  p-tabview,
  .p-tabview {
    height: 100%;
    overflow: hidden;
  }

  .p-tabview {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .p-tabview-panels {
    flex-grow: 1;
    overflow: hidden;
    // display: flex;
    // flex-direction: column;
    padding-bottom: 0;

    p-tabpanel {
      flex-grow: 1;
      overflow: hidden;
      .p-tabview-panel {
        height: 100%;
        overflow: hidden;
        .tips-tab-content {
          // flex-grow: 1;
          // overflow: auto;
        }
      }
    }
  }

  .p-tabview-nav-container {
    flex-shrink: 0;
  }

  .tips-tab-content {
    display: grid;
    gap: 10px;
    // grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: 576px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: 992px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and (min-width: 1600px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media screen and (min-width: 1900px) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    .video-card .p-card .img-container {
      max-height: 180px;
      overflow: hidden;
    }
  }
}

.video-card {
  .p-card {
    border: 1px solid transparent;
    transition: 0.4s all;
    border-radius: 10px;
    height: 100%;
    width: 100%;

    &:hover {
      border: 1px solid #eeeeee;
      background-color: rgba($color: #3247c6, $alpha: 0.05);
    }

    .p-card-body {
      height: 100%;
    }

    .img-container {
      // height: 200px;
      // position: relative;
      border: 2px solid #e9e9e9;
      border-radius: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 183px;
      img {
        border-radius: 12px;
        height: 100%;
        width: 100%;
        // aspect-ratio: 1 / 1;
        // width: 100%;
        object-fit: cover;
      }

      .play-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #000000, $alpha: 0.65);
        transition: 0.4s all;
        display: flex;
        justify-content: center;
        align-items: center;

        .play-btn {
          background-color: #000000;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &:hover {
        .play-container {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .card-title {
      margin-top: 14px;
      font-size: 18px;
      font-weight: 600;
    }

    .card-desc {
      color: #1d1d1d;
      font-size: 14px;
    }
  }
}

// styles for full width of select dropdown
app-add-content-module-detail {
  .input-group {
    .p-dropdown {
      width: 100%;
    }
  }

  .p-selectbutton {
    .p-button {
      padding: 6.5px 20px;
    }
  }
}

.media-details-container {
  background-color: $white;
  padding: 1rem 0;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 5px 6px rgba(226, 236, 249, 0.45);

  .video-player-container {
    overflow: auto;
    @media screen and (max-width: 991px) {
      // overflow: initial;
    }
  }

  .video-player {
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #e9e9e9;
  }

  .video-title {
    color: $ternary;
    font-size: 20px;
    text-transform: capitalize;

    @media screen and (max-width: 576px) {
      font-size: 15px;
    }
  }

  .video-desc {
    font-size: 14px;
    color: #1d1d1d;
  }

  .media-separator {
    height: 100%;
    width: 1px;
    background-color: rgba($color: #101010, $alpha: 0.1);
    flex-shrink: 0;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 1px;
    }
  }
  .share-btn-text {
    color: #a4a4a4;
    font-size: 12px;
    font-weight: 600;
  }
  .notes-quiz-card {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media screen and (max-width: 1024px) {
      // grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .p-card {
      border-color: #f1f1f1;

      .p-button {
        border-radius: 10px;

        @media screen and (max-width: 576px) {
          width: 100%;
          font-size: 12px;
          padding: 8px 8px;
        }
      }
    }

    .card-title {
      color: $ternary;
      font-size: 18px;
      font-weight: 600;
    }

    .card-desc {
      font-size: 14px;
      font-weight: 400;
      font-family: "Nunito", sans-serif;
    }
  }
}

.back-btn {
  .p-button {
    color: #a4a4a4 !important;
    font-weight: 600;

    svg {
      transform: rotateY(180deg);
    }
  }
}
// Progress bar
.progressbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 100%;
  height: 30px;
  border-radius: 50rem;
  background-color: #f1f1f1;
  overflow: hidden;
  .progress-dot {
    background-color: #fff;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: relative;
  }
  .progress-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-radius: 50rem;
    background-color: #48889b;
    transition: 0.4s ease-in width;
  }
}

// Common Search
app-search {
  .search-container {
    width: 100%;
    max-width: 700px;

    .search-icon {
      position: absolute;
      top: 50%;
      left: 10px;
    }
    .p-inputtext {
      padding: 0.72rem 2.6rem;
      border-radius: 10px;
      background-color: #f8fbff;
      border: 0;
      font-size: 14px;

      &::placeholder {
        color: rgba($color: #1b1b1b, $alpha: 0.5) !important;
      }
    }
  }

  &.media-search {
    @media screen and (max-width: 1024px) {
      width: auto !important;
      .search-container {
        img {
          pointer-events: none;
        }

        .p-inputtext {
          width: 45px;
          padding: 0.72rem 0.8rem;
          transition: 0.4s all;
          &::placeholder {
            opacity: 0;
          }
          &:focus {
            width: 180px !important;
            padding: 0.72rem 2.6rem;
            &::placeholder {
              opacity: 1;
            }
          }
        }
        &:focus {
          width: 180px !important;
        }
      }
    }
  }
}

.search-mobile-wrapper {
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
  }
}

// Common container with Shadow & bgwhite
.page-container {
  background-color: $white;
  padding: 1rem;
  border-radius: $border-radius;
  box-shadow: $page-container-shadow;
}

.avatar-group {
  .avatar-images {
    margin-left: -5px;
    display: flex;
  }
}

.add-answer-box {
  position: relative;
  .p-inputtext {
    padding-right: 45px;
  }
  .send-img-wrapper {
    position: absolute;
    bottom: 10px;
    right: 14px;
  }
}

.public-forum-page {
  p {
    margin: 0;
  }

  .public-forum-title {
    p {
      font-size: 22px;
    }
  }
}
.comments-btn {
  color: #a4a4a4;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
}

// Sidebar
.sidemenu-container {
  font-family: "Poppins", sans-serif;
  .sidemenu-list {
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .sidemenu-item {
      .sidemenu-link {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        padding: 1rem 1.25rem;
        gap: 10px;
        border-radius: 8px;
        transition: 0.4s all;

        .icon-arrow-toggle {
          font-size: 12px;
          margin-left: auto;
          transition: 0.4s all;

          &.expanded {
            transform: rotate(180deg);
          }
        }

        .icon-result {
          font-size: 1.25rem;
        }

        &.active-menu,
        &:hover {
          background-color: #ffffff;
          box-shadow: 0 0.125rem 2rem rgba(0, 0, 0, 0.06);
          .sidemenu-text,
          .pi {
            color: #3c4852;
          }

          .icon {
            color: #3247c6;
          }
        }
      }

      .submenu {
        &-list {
          padding: 1rem 1.25rem 0;
          list-style-type: none;

          .submenu-item {
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
          }
        }

        &-link {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          padding: 0.5rem;
          &:visited,
          .submenu-text {
            color: #3c4852;
          }
          &.active-menu,
          &:hover {
            color: $primary;
            background-color: $white;
            border-radius: 6px;

            .icon,
            .submenu-text {
              color: $primary;
            }
          }
        }
      }

      .sidemenu-text,
      .pi,
      .icon {
        font-size: 1rem;
        color: #3c4852;
        font-weight: 400;
        transition: 0.4s all;
      }
    }
  }

  .p-accordion {
    &:not(.p-disabled).p-highlight .p-accordion-header-link,
    .p-accordion-header-link {
      background-color: #ffffff !important;
      border: 0;
      color: #5e5e5e !important;
    }

    .p-accordion-tab {
      &:last-child .p-accordion-content {
        border: 0 !important;
      }
    }
  }
  @media screen and (min-width: 769px) {
    .explore-side-menu {
      .menu-close-icon {
        display: flex !important;
      }
    }
  }
  @media screen and (min-width: 991px) {
    .menu-close-icon {
      display: none !important;
    }
  }

  @media screen and (max-width: 769px) {
    background-color: #fdf6f7;
  }
}

.page-card {
  &-title {
    font-size: 20px;
    font-weight: 600;
    color: $ternary;
    margin: 0 0 5px;
    font-family: "Poppins";
  }
  &-subtext {
    font-size: 14px;
    color: #54577a;
    font-weight: 500;
    font-family: "Nunito", sans-serif;
    margin: 0 0 1rem;
  }
}

.badge {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  border-radius: 50rem;
  font-size: 12px;
  font-weight: 500;
  position: relative;

  &.dot {
    padding-left: 28px;
    &:before {
      content: "";
      position: absolute;
      top: 11px;
      left: 14px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  &-success {
    background-color: rgba($color: $success, $alpha: 0.05);
    color: $success;

    &.dot {
      &:before {
        background-color: $success;
      }
    }
  }
  &-danger {
    background-color: rgba($color: #ea2149, $alpha: 0.05);
    color: #ea2149;
    &.dot {
      &:before {
        background-color: #ea2149;
      }
    }
  }
  &-warning {
    background-color: rgba($color: #f3b311, $alpha: 0.09);
    color: #f3b311;
    &.dot {
      &:before {
        background-color: #f3b311;
      }
    }
  }
  &-info {
    background-color: rgba($color: $info, $alpha: 0.09);
    color: $info;
    &.dot {
      &:before {
        background-color: $info;
      }
    }
  }

  &-primary {
    background-color: rgba($color: $primary, $alpha: 0.05);
    color: $primary;
    &.dot {
      &:before {
        background-color: $primary;
      }
    }
  }

  &-secondary {
    background-color: rgba($color: $secondary, $alpha: 0.05);
    color: $secondary;
    &.dot {
      &:before {
        background-color: $secondary;
      }
    }
  }
}

.fixed-width-dropdown {
  .p-dropdown {
    min-width: 150px;
  }
}

.selected-group {
  background-color: rgba($color: #e54f6d, $alpha: 0.05);
}

// Left panel for left & right section pages
.left-panel {
  height: 50%;
  max-width: 100%;
  @include media-breakpoint-up(lg) {
    flex: 0 0 400px;
    max-width: 400px;
    height: 100%;
  }

  .group-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .group-item {
      font-size: 13px;
      transition: 0.4s all;
      padding: 0.5rem 1rem;
      border-radius: 10px;
      color: #484848;
      &:hover {
        background-color: rgba($color: #e54f6d, $alpha: 0.05);
      }
    }
    .group-video-thumbnail {
      flex-shrink: 0;
      img {
        width: 100px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .group-video-text {
      color: #1d1d1d;
      opacity: 0.6;
    }
    .group-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #7e0ea3;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }
}

app-or-assign-task {
  @media screen and (max-width: 991px) {
    .inner-wrapper {
      overflow-y: auto !important;
    }
    .left-panel {
      height: auto;
    }

    .group-list {
      max-height: 350px;
    }
  }
}

.form-group {
  label {
    font-size: 14px;
    font-weight: 500;
    color: #54577a;
    margin-bottom: 6px;
    display: inline-block;
  }
}

.custom-input-btn {
  position: relative;

  p-button {
    position: absolute;
    right: 12px;
    top: 6px;
    z-index: 9;
  }

  .p-button {
    font-size: 12px;
    padding: 5px 6px;
  }
}

.stepper {
  flex: 0 0 300px;
  max-width: 300px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  &-item {
    font-weight: 600;
    color: #5f6a7b;

    &.ischild-menu {
      flex-direction: column;
      .sub-menu {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .dot-wrapper {
          display: flex;
          justify-content: center;
          align-self: normal;
          flex-shrink: 0;
          width: 44px;
          background-color: rgba($color: #ebeef1, $alpha: 0.85);
          padding: 0.85rem 0 0;
        }

        .dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #8591a4;
        }

        .sub-menu-text {
          padding: 0.5rem 0 0;
        }

        &.submenu-active {
          .dot {
            background-color: #4154ca;
          }
          .sub-menu-text {
            color: #4154ca;
          }
        }

        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    .stepper-count-wrapper {
      background-color: rgba($color: #ebeef1, $alpha: 0.85);
      padding: 0.5rem;
    }
    &:first-child {
      .stepper-count-wrapper {
        border-top-right-radius: 50rem;
        border-top-left-radius: 50rem;
      }
    }
    &:last-child {
      .stepper-count-wrapper {
        border-bottom-right-radius: 50rem;
        border-bottom-left-radius: 50rem;
      }
    }
    .stepper-count {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #8d9bb1;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.active {
      color: #30313d;
      .stepper-count {
        color: $white;
        background-color: #4154ca;
      }
    }
  }

  &.mobile-view {
    display: none;
  }

  @media screen and (max-width: 991px) {
    flex-direction: row;
    flex: 0 0 auto;
    overflow-x: auto;
    max-width: none;
    background-color: $white;
    gap: 1.5rem;
    border-bottom: 1px solid #f1f1f1;
    .stepper-item {
      white-space: nowrap;
      border-bottom: 4px solid transparent;
      &.active {
        border-bottom-color: #4154ca;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      .stepper-count-wrapper {
        background-color: transparent;
      }

      &:last-child {
        padding-right: 1rem;
      }
    }

    &::-webkit-scrollbar {
      width: 100%;
      border-radius: 8px;
      height: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 20px;
    }

    &.mobile-view {
      display: flex;
      gap: 0.5rem;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

      .sub-menu {
        padding: 0.5rem;
        &.ischild-menu {
          list-style-type: none;
          white-space: nowrap;

          .sub-menu-text {
            color: #8591a4;
          }
          &.submenu-active {
            .sub-menu-text {
              color: #4154ca;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 991px) {
//   .nav-tabs .nav-item {
//     display: inline-block;
//     margin-right: 10px;
//   }

//   .nav-tabs .nav-link {
//     border: none;
//     padding: 10px;
//     background-color: #f8f9fa;
//     color: #5f6a7b;
//     border-radius: 0.25rem;
//     margin-bottom: -1px;
//     font-size: 0.9rem;
//   }

//   .nav-tabs .nav-link.active {
//     background-color: #4154ca;
//     color: white;
//   }
// }

.student-profile {
  &-title {
    font-size: 24px;
    font-weight: 500;
    color: #30313d;
    font-family: "Poppins";
    margin: 0;
  }

  &-subtext {
    font-size: 14px;
    color: #414552;

    a {
      color: #4154ca;
      font-weight: 500;
    }
  }
}

.personality {
  &-cards {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and (min-width: 576px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: 992px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media screen and (min-width: 1500px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .personality_type_input {
      display: none;
      &:checked {
        & ~ .personality-card {
          .personality-icon,
          .tick-icon {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  &-card {
    display: block;
    position: relative;
    color: #30313d;
    border-radius: 8px;
    padding: 0.725rem 1rem;
    text-align: center;
    transition: 0.4s all;
    cursor: pointer;
    h6 {
      font-size: 16px;
      transition: 0.4s all;
    }

    .personality-icon {
      position: absolute;
      bottom: 0;
      left: -5px;
      visibility: hidden;
      opacity: 0;
      transition: 0.4s all;
    }

    .tick-icon {
      position: absolute;
      bottom: -14px;
      right: -14px;
      border: 4px solid $white;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: $box-shadow-sm;
      visibility: hidden;
      opacity: 0;
      transition: 0.4s all;

      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}

$cards-type: (
  "analysts": #88619a,
  "diplomats": #33a474,
  "sentinals": #4298b4,
  "explorers": #e4ae3a,
);

.personality_type_input {
  & ~ .personality-card {
    @each $key, $value in $cards-type {
      &.#{$key} {
        background-color: rgba($color: $value, $alpha: 0.12);

        h6 {
          color: $value;
          margin: 0;
        }
        .tick-icon {
          background-color: lighten($color: $value, $amount: 15);
        }
      }
    }
  }
  &:checked {
    & ~ .personality-card {
      color: $white;
      h6 {
        color: $white;
      }
      @each $key, $value in $cards-type {
        &.#{$key} {
          background-color: $value;
          .personality-icon,
          .tick-icon {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

app-dashboard {
  .p-card {
    border: 0;
    box-shadow: 0 0 0.25rem rgba(226, 236, 249, 0.45);
  }

  .see-all-d-mobile {
    display: block;
    margin-top: 24px;
    text-align: center;
    position: relative;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: rgba($color: #000000, $alpha: 0.08);
      transform: translateY(-50%);
      z-index: -1;
    }

    @media screen and (min-width: 1025px) {
      display: none;
    }

    .p-button {
      background-color: #ffffff;
      border: 1px solid rgba($color: #000000, $alpha: 0.08);
      border-radius: 50rem;
      padding: 2px 4px;
    }
  }
}

// list view in dashboard chart cards
.student {
  &-list {
    font-weight: 400;
    font-size: 14px;
    color: #1e212a;
    font-weight: bold;
    max-height: 300px;
    overflow: auto;

    p {
      font-weight: normal;
      color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  &-item {
    padding: 0.5rem 0.25rem;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($color: #979797, $alpha: 0.3);
    }
  }

  &-fig-wrapper {
    display: flex;
    align-items: center;
    background-color: #e4e4e4;
    border-radius: 50rem;

    .avatar {
      width: 34px;
      height: 34px;
    }

    .student-fig-number {
      display: inline-block;
      padding: 0 0.75rem;
    }
  }
}

// scss variable for Topper container
$topper-height: 200px;
$topper-numbering-size: 28px;
.top-records-grid {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}
.topper-details {
  color: #1e212a;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 2px;
  // align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
  z-index: 9;
  position: absolute;
  max-width: 150px;

  &.second {
    top: -93px;
    left: -25px;
  }
  &.first {
    top: -94px;
    left: -28px;
  }
  &.third {
    top: -93px;
    left: -28px;
  }

  .topper-avatar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 4px solid white;
    margin: 0;
    overflow: hidden;
    margin-top: -50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    color: white;
  }

  .most-lessons-avatar {
    background-color: #e8637e;
  }
  .most-time-avatar {
    background-color: #3348c6;
  }
  .most-questions-avatar {
    background-color: #9868ff;
  }

  .topper-title {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
  }

  .topper-email {
    color: rgba($color: #000000, $alpha: 0.5);
    font-size: 13px;
    line-height: 1.2;
    margin: 0;
  }

  .toppper-time {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
  }
}

.timeline-toppers {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: flex-end;
  gap: 0.5rem;
  color: $white;
  padding-top: 150px;

  .topper {
    height: $topper-height;
    width: 100px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;

    &-numbering {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $topper-numbering-size;
      width: $topper-numbering-size;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      font-weight: 500;
      border-radius: 50%;
      background-color: rgba($color: #ffffff, $alpha: 0.2);
      z-index: 2;
    }

    &.second {
      height: $topper-height - 110px;
    }
    &.third {
      height: $topper-height - 130px;
    }
  }

  &.most-time {
    .topper {
      &.first {
        background-color: #3348c6;
      }
      &.second {
        background-color: rgba($color: #3348c6, $alpha: 0.5);
      }
      &.third {
        background-color: rgba($color: #3348c6, $alpha: 0.4);
      }
    }
  }
  &.most-lessons {
    .topper {
      &.first {
        background-color: #e8637e;
      }
      &.second {
        background-color: rgba($color: #e8637e, $alpha: 0.5);
      }
      &.third {
        background-color: rgba($color: #e8637e, $alpha: 0.4);
      }
    }
  }
  &.most-questions {
    .topper {
      &.first {
        background-color: #9868ff;
      }
      &.second {
        background-color: rgba($color: #9868ff, $alpha: 0.5);
      }
      &.third {
        background-color: rgba($color: #9868ff, $alpha: 0.4);
      }
    }
  }
}

.slider-image {
  height: 220px;
  @media screen and (max-width: 767px) {
    height: 225px;
  }
}

// top banners for dashobard

.top-banners {
  position: relative;
  // background: rgba($color: #ecf3fe, $alpha: 0.85);
  background: #ecf2fd url("./assets/images/student-portal/banner.svg") no-repeat
    right bottom;
  color: $white;
  padding: 1.5rem;
  height: 220px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 40px;

  &.banner-second {
    background-image: url("./assets/images/student-portal/web_slider.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 767px) {
    background-image: url("./assets/images/student-portal/banner-mobile.svg");
    height: 225px;

    &.banner-second {
      background-image: url("./assets/images/student-portal/mobile_slider.png");
      background-position: center bottom;

      .small {
        font-size: 12px;
      }
    }
    .banner-text,
    .banner-title {
      max-width: 180px;
    }

    .banner-title {
      font-size: 18px;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
    }
  }

  p {
    color: #384651;
    margin-top: 0;
    font-weight: bold;
  }

  .text-fs-light {
    color: #74758d;
  }

  &-img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1;
  }

  .banner-title {
    color: #3247c6;
    text-transform: uppercase;
    font-family: "Poppins";
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

p-button {
  &.reply-btn {
    .p-button {
      color: #3247c6;
    }
  }
}

/* grade-selector.component.css */
.grade {
  &-button-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px; /* Adjust the space between buttons */
    margin-top: 1rem;
    overflow-x: auto;
    position: relative;
    padding: 0.5rem 0;

    &::-webkit-scrollbar {
      height: 4px;
    }
  }
  &-button {
    padding: 10px 20px;
    border: 1px solid #dddfe2; /* Light grey border */
    border-radius: 20px; /* Rounded corners */
    background-color: white;
    color: #7f8896;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
    &.selected,
    &:hover {
      color: #4154ca; /* Your color for the special button */
      border-color: transparent;
      background-color: #eff0fa;
    }
  }
  &-multi-button {
    padding: 10px 20px;
    border: 1px solid #dddfe2; /* Light grey border */
    border-radius: 20px; /* Rounded corners */
    background-color: white;
    color: #7f8896;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    white-space: nowrap;
    &.selected,
    &:hover {
      color: #ffffff; /* Your color for the special button */
      border-color: transparent;
      background-color: #4154ca;
    }
  }

  &-navigation-button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: -5px;
    bottom: 0;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
    }
  }
}

.academic-upload-container {
  border-radius: $border-radius;
  padding: 1rem 1rem 1rem 1rem;
  color: #30313d;
  font-weight: bold;
  background: url("./assets/images/algoed-logo.png") no-repeat bottom right;
  background-color: #f4f5ff;

  .sub-text {
    color: #414552;
    opacity: 0.8;
    font-size: 14px;
    margin: 0;
    font-weight: normal;
  }

  @media screen and (min-width: 576px) {
    padding-right: 7rem;
  }
}

.form-label {
  display: inline-block;
  font-size: 14px;
  color: #3b3b3b;
  font-weight: 600;
}

.underline-tab-menu {
  .p-tabmenu {
    .p-tabmenu-nav,
    .p-tabmenu-nav-content {
      background-color: $white;
    }

    .p-tabmenu-nav {
      padding: 0;
    }

    .p-tabmenuitem {
      .p-menuitem-link {
        position: relative;
        color: #414552;
        padding: 0.5rem 1rem 1rem;
        border-radius: 0 !important;
        font-weight: 500;

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          width: 100%;
          height: 4px;
          border-radius: 50rem;
          background-color: #d7d7d7;
        }
      }
      &.p-highlight,
      &:hover {
        .p-menuitem-link {
          &:after {
            background-color: #4154ca;
          }
        }
      }
    }
  }
}

.p-inputtext {
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.new-login-wrapper {
  // height: 100vh;
  height: 100%;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    overflow: auto;
    .new-login-wrapper {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
    .login-content-section {
      flex-grow: 1;
    }
  }
  .login-page-loader {
    flex: 0 0 45%;
    max-width: 45%;
    @media screen and (max-width: 991px) {
      flex: auto;
      max-width: 100%;
      width: 100%;
    }
  }

  .login-static-section {
    position: relative;
    display: flex;
    flex: 0 0 45%;
    max-width: 45%;
    padding: 5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: $white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;

    .login-title {
      font-size: 2.5rem;
      color: $white;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 4.5rem;
      margin-bottom: 1rem;
    }

    .text-competitions,
    .text-research {
      position: relative;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: -1;
      }
    }

    .text-competitions {
      &:after {
        height: 100%;
        background-color: #e6593b;
        clip-path: polygon(100% 0.3%, 0% 15.08%, 0% 100%, 100% 89.22%);
      }
    }

    .text-research {
      &:after {
        background-image: url("./assets/images/login/text-curve-underline.svg");
        background-repeat: no-repeat;
        background-size: contain;
        height: 1rem;
        bottom: -8px;
      }
    }

    .text-subtitle {
      font-size: 1.8rem;
      line-height: 1.5;
      color: $white;
      letter-spacing: 2px;
      margin-bottom: 2.5rem;
    }

    .text-follow {
      font-size: 1.5rem;
      letter-spacing: 1px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 175px;
        top: 50%;
        transform: translateY(-50%);
        width: 150px;
        height: 3px;
        background-color: rgba($color: #ffffff, $alpha: 0.3);
      }
    }

    &.auth-left-content {
      background-image: url("./assets/images/login/blue-bg-login.png");
      background-repeat: no-repeat;
      background-size: 100% 80%;
      padding: 2rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:before {
        content: "";
        position: absolute;
        top: 15%;
        right: 0;
        width: 365px;
        height: 40%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("./assets/images/login/alpha.png");
        z-index: -1;
      }

      .login-banner-girl {
        position: absolute;
        right: -35px;
        bottom: 0;
        height: 80%;
        z-index: 2;
      }

      .logo-w {
        width: 142px;
      }

      .default-content {
        position: relative;
        z-index: 1;
        .img-dot-group {
          width: 160px;
        }

        .img-plus {
          width: 26px;
          height: 26px;
          margin-bottom: 20px;
        }
      }
      .img-circle {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: 57%;
        transform: translateX(-50%);
        opacity: 0.8;
      }

      @media screen and (min-width: 1800px) {
        max-width: 900px;
      }

      @media screen and (max-width: 1460px) {
        .login-title {
          font-size: 1.8rem;
          line-height: 3.5rem;
        }

        .text-subtitle {
          font-size: 1.5rem;
        }

        .text-follow {
          font-size: 1.25rem;
          margin-bottom: 1rem;
        }

        .social-icons {
          img {
            width: 44px;
            height: 44px;
          }
        }

        .default-content {
          .img-dot-group {
            width: 140px;
          }
        }

        .text-research {
          &:after {
            bottom: -14px;
          }
        }
      }

      @media screen and (max-width: 991px) {
        flex: 0 0 100%;
        max-height: 65vh;
        max-width: 756px;
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
      }

      @media screen and (max-width: 756px) {
        padding: 1rem;

        &:before {
          width: 300px;
          height: 210px;
        }

        .login-title {
          font-size: 1.25rem;
          line-height: 2.5rem;
          text-wrap: balance;
        }

        .text-subtitle {
          font-size: 1rem;
        }

        .text-follow {
          font-size: 0.875rem;
          margin-bottom: 0.725rem;

          &:after {
            left: 105px;
            width: 85px;
          }
        }

        .social-icons {
          img {
            width: 38px;
            height: 38px;
          }
        }
      }

      @media screen and (max-width: 576px) {
        max-height: 315px;

        &:before {
          width: 142px;
          height: 90px;
          right: 25px;
          top: 50px;
        }

        .default-content {
          .img-dot-group {
            width: 75px;
            position: absolute;
            top: 175px;
            right: 120px;
            margin-bottom: 0;
          }

          .img-plus {
            position: absolute;
            top: 211px;
            left: 0;
            margin: 0;
            height: 16px;
            width: 16px;
          }
        }

        .login-title {
          margin-bottom: 0.5rem;
          line-height: 2.2rem;
        }

        .text-subtitle {
          margin-bottom: 1rem;
        }

        .login-banner-girl {
          height: 85%;
        }

        .img-circle {
          left: 100px;
          top: 212px;
          width: 16px;
          height: 16px;
        }
      }
    }

    &.competitions-bg {
      padding: 0;
      // background: #a50332;
      // max-width: 600px;
      overflow: hidden;
      max-width: 42%;
      border-radius: 0;
      figure {
        height: 100%;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 1240px) {
      .hero-img {
        width: 240px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 30px;
    }

    @media screen and (max-width: 768px) {
      &.competitions-bg {
        flex: auto;
        max-width: 100%;
      }
    }
    @media screen and (min-width: 1800px) {
      &.competitions-bg {
        max-width: 30%;
      }
    }
  }

  .follow-text {
    position: relative;
    width: 100%;
    font-size: 20px;
    text-align: center;
    z-index: 1;

    > span {
      background-color: transparent;
      padding: 2px 12px;
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 14px;
      background-color: rgba($color: white, $alpha: 0.5);
      height: 1px;
      z-index: -1;
    }

    &.dark {
      color: rgba(#1a1a1a, 0.35);
      font-size: 14px;

      &:after,
      &:before {
        top: 10px;
        background-color: rgba($color: black, $alpha: 0.11);
        width: 45%;
      }
    }

    &::before {
      left: 0;
      width: 30%;
    }
    &::after {
      right: 0;
      width: 30%;
    }
  }

  .login-content-section {
    @media screen and (min-width: 992px) {
      overflow-y: auto;
    }

    .border-seperator {
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.11);
    }

    .auth-text {
      color: rgba($color: #1a1a1a, $alpha: 0.7);
      font-weight: 500;
      font-size: 14px;

      a {
        color: #065ad8;

        &:hover {
          color: darken($color: #065ad8, $amount: 5);
        }
      }
    }

    .card-padding {
      // height: 100vh;
      padding: 5rem;
      max-width: 756px;
      width: 100%;
      margin: 0 auto;

      &.auth-content {
        padding: 3rem 4rem;
      }
    }

    label {
      color: rgba($color: #1a1a1a, $alpha: 0.75);
      font-weight: 500;
      font-size: 16px;
    }

    h3 {
      color: #101828;
      font-size: 22px;
    }

    .sign-up {
      a {
        color: #065ad8 !important;
      }
    }
    .display-block,
    .p-primary {
      padding: 14px 16px;
      span {
        font-size: 16px;
      }
    }

    .checkbox-text {
      color: rgba($color: #1a1a1a, $alpha: 0.7);
    }

    @media screen and (max-width: 768px) {
      .card-padding {
        padding: 1.5rem 1rem !important;
      }
    }
  }

  .p-inputtext {
    padding: 12.5px 1rem;
    border: 1px solid rgba($color: #000000, $alpha: 0.11);
    border-radius: 6px;
    outline: 0;

    &.disabled,
    &:disabled {
      background-color: #f1efef;
      opacity: 1;
    }

    &:focus {
      outline: 0;
      border-color: #065ad8;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba($color: #1a1a1a, $alpha: 0.5);
    }
  }

  .subscribe-text {
    color: rgba($color: #000000, $alpha: 0.7);
  }

  .terms-text {
    color: rgba($color: #000000, $alpha: 0.5);

    a {
      color: #065ad8;
    }
  }

  .otpTimer {
    color: #101828;
    font-size: 22px;
    font-weight: 600;
  }

  .subscribe-text,
  .terms-text,
  .resend-text {
    font-size: 14px;
    font-weight: 500;
  }

  .auth-link-text {
    color: #065ad8;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      color: darken($color: #065ad8, $amount: 5);
    }
  }

  .resend-text {
    color: rgba($color: #1a1a1a, $alpha: 0.7);
    font-weight: 400;

    span {
      font-weight: 500;
      cursor: pointer;
    }
  }

  .otp-input {
    border-color: #e0e0e0 !important;
    box-shadow: 0 0 0.075rem rgba(0, 0, 0, 0.15);
    transition: 0.4s all;
    font-size: 24px !important;

    &:focus,
    &:focus-visible {
      border-color: #065ad8 !important;
      box-shadow: 0 0 0.075rem rgba(#065ad8, 0.15);
      outline: 0;
    }
  }

  .form-control-password {
    position: relative;
    .p-inputtext {
      padding-right: 40px;
    }
    .pi {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      color: rgba($color: #1a1a1a, $alpha: 0.7);
      font-size: 16px;
    }
  }

  .btn-auth-primary,
  .btn-auth-google {
    padding: 11px 16px;
    font-weight: 500;
    box-shadow: none;
    border-radius: 6px;
    font-size: 16px;
  }
  .btn-auth-primary {
    color: #ffffff;
    background-color: #065ad8;
    border: 1px solid #065ad8;

    &:hover,
    &:active {
      background-color: darken($color: #065ad8, $amount: 5);
    }
  }

  .btn-auth-google {
    border: 1px solid #e0e0e0;
    background-color: $white;
    color: rgba($color: #1a1a1a, $alpha: 0.7);
    &:hover,
    &:active {
      background-color: darken($color: $white, $amount: 2.5);
      border-color: #e0e0e0;
    }
  }
}

.or {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  position: relative;
  span {
    // background: #f5f8ff;
    padding: 10px 0;
    display: inline-block;
  }
  &:before {
    content: "";
    border: 1px solid rgba($color: #000000, $alpha: 0.11);
    display: block;
    position: absolute;
    width: 45%;
    top: 50%;
  }
  &:after {
    content: "";
    border: 1px solid rgba($color: #000000, $alpha: 0.11);
    display: block;
    position: absolute;
    width: 45%;
    top: 50%;
    right: 0;
  }
}

// Student Competition
app-student-competitions {
  .border-top {
    border-color: rgba($color: #000000, $alpha: 0.1) !important;
  }
}
.competition-heading {
  font-size: 20px;
}

app-startup,
app-explore-competitions,
app-registration-stages {
  .btn {
    // border-radius: 50rem;
    outline: 0;
    box-shadow: none;
    font-size: 14px;
  }
  .btn-ternary-light {
    background-color: rgba($color: #ff476c, $alpha: 0.2);
    color: #e54f6d;
    &:hover,
    &:active {
      background-color: rgba($color: #ff476c, $alpha: 1) !important;
      color: #ffffff !important;
    }
  }
  .btn-gray-secondary {
    background-color: rgba($color: #ffffff, $alpha: 1);
    border-color: #dadce0;
    color: #383838;
    &:hover,
    &:active {
      background-color: rgba($color: #dadce0, $alpha: 1) !important;
      color: #383838 !important;
      border-color: #dadce0 !important;
    }
  }
  .btn-join-now {
    background-color: rgba($color: #0475e1, $alpha: 1);
    border-color: #0475e1;
    color: #ffffff;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    &:hover,
    &:active {
      background-color: rgba($color: #0475e1, $alpha: 0.9) !important;
      color: #ffffff !important;
      border-color: #0475e1 !important;
    }

    &:disabled,
    &.disabled {
      background-color: rgba($color: #0475e1, $alpha: 1);
      opacity: 0.6;
      color: #ffffff !important;
      border-color: rgba($color: #0475e1, $alpha: 0.6);
    }
  }
  .btn-view-details {
    background-color: rgba($color: #ffffff, $alpha: 1);
    border-color: #ffffff;
    color: #656565 !important;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    &:hover,
    &:active {
      background-color: rgba($color: #f3f3f3, $alpha: 1) !important;
      color: #656565 !important;
      border-color: #ffffff !important;
    }
  }
  app-my-competitions {
    .btn-join-now {
      border-radius: 50rem;
    }
  }
}
.dropdown {
  &.competition-dropdown {
    border-right: 1px solid #dce5ea;
    .btn-primary {
      border-radius: 50rem;
      @media screen and (max-width: 460px) {
        font-size: 12px;
      }
    }
  }
}

.competition-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  gap: 30px;

  &.individual {
    grid-template-columns: auto;

    .card {
      border-radius: 10px;
      border: 1px solid #f0f0f0;
      .card-figure {
        width: 100px;
        height: 100px;

        &.md {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .card {
    border: 1px solid rgba($color: #000000, $alpha: 0.12);
    border-radius: 16px;
    .card-figure {
      // height: 175px;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      aspect-ratio: 16 / 9;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 0.5rem;
      }

      .btn-share {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;

        img {
          object-fit: contain;
        }
      }
    }

    .card-title {
      font-size: 18px;
      line-height: 23px;
      color: #30313d;
    }
  }

  // @media screen and (min-width: 769px) and (max-width: 991px) {
  //   .card {
  //     .card-figure {
  //       height: 220px;
  //     }
  //   }
  // }

  @media screen and (max-width: 991px) {
    .card {
      .card-figure {
        flex-shrink: 0;
      }

      .text-priority {
        font-size: 12px;
      }

      .competition-card-footer {
        border-top: 1px solid rgba($color: #000000, $alpha: 0.12);
      }
    }
  }
}

.join-competition-canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 99;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.2);
    z-index: 8;
  }

  .canvas-content {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 900px;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: $white;
  }

  .btn-group {
    .btn {
      border-color: #dadce0;
      background-color: $white;
      transition: none;

      &.selected {
        span {
          color: #0475e1;
        }
        svg {
          path {
            fill: #0475e1;
          }
        }
      }

      &:not(:first-child) {
        border-left: transparent;
        &:active,
        &:hover,
        &:focus {
          border-left-color: transparent;
        }
      }
      &:not(:last-child) {
        &:active,
        &:hover,
        &:focus {
          border-right-color: transparent;
        }
      }

      span {
        color: #767676;
      }
    }
  }

  .card {
    border-radius: 16px;
    border: 1px solid #d0d4e4;

    h5 {
      font-size: 20px;
      color: #30313d;
    }

    .team-name {
      font-size: 16px;
      color: #30313d;
      font-weight: 500;
    }

    .team-desc {
      font-size: 16px;
      color: #797979;
      font-weight: 500;
    }
  }

  .competition-canvas-img {
    width: 180px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      width: 100px;
      height: 60px;
    }
  }
}

.competition-progress-steps {
  &::-webkit-scrollbar {
    height: 0; /* or 0 for no scrollbar */
  }
}

.leader-modal,
.join-competition-modal,
.student-detail-popup,
.verified-modal {
  position: relative;

  .p-dialog-header-icons {
    border-radius: 50%;
    background-color: rgba($color: $white, $alpha: 1);
    svg path {
      fill: #222222;
    }

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.9);
    }
  }

  .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background-color: rgba($color: $white, $alpha: 0.9);
  }

  .p-dropdown-label {
    color: #000000;
  }

  .modal-title {
    font-size: 1.25rem;
    color: #222222;
  }

  .modal-subtitle {
    font-size: 1rem;
    color: rgba($color: #1a1a1a, $alpha: 0.7);
  }

  &.not-leader-board {
    .invite-card {
      &.card {
        border: 0;
        box-shadow: none;

        .card-body {
          padding: 0;
        }
      }
    }
  }

  &.payment-modal {
    .p-dialog-content {
      padding: 0;
    }
    .header-content {
      background-color: #fee8ec;
      text-align: center;
      padding: 1.5rem;
      position: relative;

      .close-btn {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        padding: 0;
        font-size: 2rem;
        height: 24px;
        width: 24px;
        color: #222222;

        span {
          line-height: 1rem;
        }
      }
    }
  }
}

.confirm-team-modal {
  .p-dialog-header {
    padding: 1rem 1.5rem;
  }
}

.verified {
  &-modal {
    margin-top: 4.5rem;
    figure {
      margin: 0;
      width: 130px;
    }

    .p-dialog-content {
      color: #1a1a1a;
    }
  }
  &-check {
    width: 20px;
  }

  &-info {
    margin-top: 0;
    li {
      padding-top: 1rem;
      img {
        margin-right: 0.5rem;
      }
      &:not(:last-child) {
        padding: 1rem 0;
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
}

.round-delete-modal {
  .btn {
    min-height: 44px;
  }
}

.video-list {
  @media screen and (max-width: 1024px) {
    .video-card {
      .p-card {
        .p-card-body {
          padding: 0;
        }
      }
    }
  }
}

.join-competition-modal {
  .p-dialog-title {
    text-transform: none;
  }
}

swiper-container {
  &::part(button-next),
  &::part(button-prev) {
    --swiper-navigation-size: 30px;

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      height: 10px;
      width: 0;
      border-radius: 50%;
      box-shadow: 1px 1px 20px 4px rgba(0, 0, 0, 0.5);
    }

    @media screen and (max-width: 1024px) {
      --swiper-navigation-size: 0;
      display: none;
    }
  }

  // &::part(pagination) {
  //   @media screen and (min-width: 1024px) {
  //     display: none;
  //   }
  // }

  &::part(bullet-active) {
    background-color: #ffffff;
  }
  &::part(bullet) {
    background-color: #ffffff;
    opacity: 0.5;
  }
}

// progress
.progress {
  width: 220px;
  box-shadow: none;
  --bs-progress-bg: rgba(0, 0, 0, 0.13);
  --bs-progress-height: 0.5rem;

  .progress-bar {
    --bs-progress-bar-bg: #0273ea;
  }

  @media screen and (max-width: 600px) {
    width: 160px;
  }
}

.progress-bar-text {
  flex-shrink: 0;
  color: #a6a6a6;
  font-size: 14px;
}

.text-state-heading {
  font-size: 18px;
  color: #30313d;
  margin: 0;
  line-height: 1.2;
}

.competitions-dashboard-container {
  display: flex;
  flex-direction: column;
}

.dashboard-competitions-card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 10px;

  .competition-img {
    height: 100px;
    width: 180px;
    border-radius: 8px;
    flex-shrink: 0;
    overflow: hidden;
    border: 1px solid rgba($color: #000000, $alpha: 0.1);

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  h5 {
    color: #30313d;
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
  }

  .team-desc {
    font-size: 16px;
    color: #797979;
    font-weight: 500;
  }

  .team-name {
    font-size: 16px;
    color: #30313d;
    font-weight: 500;
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #d0d4e4;
    margin: 24px 0;
  }

  .badge {
    font-weight: 600;
  }

  @media screen and (max-width: 768px) {
    .competition-img {
      width: 100px;
      height: 60px;
    }

    h5 {
      font-size: 18px;
    }

    .text-state-heading {
      font-size: 16px;
    }

    .team-desc,
    .team-name {
      font-size: 14px;
    }
  }
}

.btn {
  &.btn-danger {
    color: $white;
  }
  &.btn-outline-danger {
    &:hover,
    &:active {
      color: $white;
    }
  }
  &.btn-paid {
    background-color: rgba($color: #3247c6, $alpha: 0.2);
    border-color: rgba($color: #3247c6, $alpha: 0.2);
    color: #3247c6;
    font-weight: 500;
    border: 0;
    &:hover,
    &:active {
      background-color: rgba($color: #3247c6, $alpha: 0.15) !important;
      color: #3247c6 !important;
      border-color: rgba($color: #3247c6, $alpha: 0.15) !important;
    }
    &:disabled,
    &.disabled {
      opacity: 1;
    }
  }

  &.btn-white-download {
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 10px;
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0b66e4;
    font-weight: 500;
    box-shadow: 0 0 0.5rem 2px rgba(226, 236, 249, 0.4);

    &:hover,
    &:active {
      color: darken($color: #0b66e4, $amount: 1.5);
      border-color: darken($color: #ffffff, $amount: 1.5);
      background-color: darken($color: #ffffff, $amount: 1.5);
    }
  }

  &.btn-review-dropdown {
    position: relative;
    width: 100%;
    border-color: #e2e3e6;
    border-radius: 8px;
    background-color: $white;
    box-shadow: none;
    text-align: left;

    &:after {
      position: absolute;
      right: 12px;
      top: 16px;
    }

    &:active,
    &:hover {
      border-color: #e2e3e6;
    }
  }
}

.manage-student-list {
  .p-datatable-header {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// card used in competition for latest updates
.newest-updates-card-wrapper {
  max-width: 348px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .newest-updates-card {
    // background-color: rgba($color: #e5f9ff, $alpha: 0.52);
    background-color: $white;
    border: 1px solid rgba($color: $black, $alpha: 0.12);
    // background-image: url("./assets/images/competition/bg-newest-card.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    .card-title {
      font-size: 20px;
      color: #1b6fd5;
      font-weight: 600;
      margin: 0;
    }

    .p-inputtext {
      border-radius: $border-radius;
      border: 1px solid #e1e6ea;
    }

    .form-control-divider {
      height: 1px;
      width: 100%;
      background-color: #e5eeff;
    }
  }

  @media (max-width: 991px) {
    max-width: 100%;
    .newest-updates-card {
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      .card-title {
        font-size: 18px;
      }
      .p-inputtext {
        height: 40px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .newest-updates-card {
      max-width: 100%;
    }
  }
}

.get-updates-card {
  &.card {
    border: 1px solid rgba($color: $black, $alpha: 0.12);
    .p-inputtext {
      border-radius: $border-radius;
      height: 38px;
      border: 1px solid #e1e6ea;
    }
    @media screen and (max-width: 991px) {
      --bs-card-bg: none;
      box-shadow: none;
      border: 0;
      .card-body {
        padding: 0;
      }
      .btn {
        width: 100%;
      }
    }
  }
}

// app-explore-competitions,
// app-startup {
//   @media (min-width: 992px) {
//     .newest-updates-card,
//     .btn-subscribe-wrapper {
//       position: -webkit-sticky;
//       position: sticky;
//       top: 0;
//     }
//   }
// }

// app-explore-competitions {
//   @media (min-width: 992px) {
//     .newest-updates-card {
//       top: 15px;
//     }
//   }
// }

.co-detail-card-wrapper {
  gap: 30px;
}

.public-competition-card-wrapper {
  .competition-cards {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.subscribe-card {
  max-width: 540px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: rgba($color: #e5f9ff, $alpha: 0.52);
  border: 1px solid rgba($color: #75ddff, $alpha: 0.31);
  border-radius: 12px;
  background-image: url("./assets/images/competition/bg-newest-card.png");
  background-repeat: no-repeat;
  background-size: cover;
  .card-title {
    font-size: 20px;
    font-weight: 600;
    color: #1b6fd5;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    max-width: initial;

    .card-title {
      font-size: 18px;
    }
  }
}

.share-items {
  grid-template-columns: repeat(6, minmax(0, 1fr));

  .share-icon {
    border-radius: 50%;
    width: 48px;
    justify-content: center;
    height: 48px;
    display: flex;
    align-items: center;

    &.sm {
      width: 34px;
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.btn-page-share {
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  // border-radius: 50rem;
  border: 1px solid rgba($color: #3247c6, $alpha: 0.2);
  color: #3247c6;
  padding: 0.375rem 1rem;

  &:active,
  &:hover {
    background-color: rgba($color: #3247c6, $alpha: 0.12) !important;
    color: #3247c6 !important;
    border-color: rgba($color: #3247c6, $alpha: 0.2) !important;
  }
}

// Explore page styles
.explore-page-banner {
  // height: 140px;
  width: 100%;
  background-image: url("./assets/images/explore-page/explore-banner-lg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  h6 {
    font-size: 28px;
    color: #1b6fd5;
    text-align: center;
  }

  @media screen and (max-width: 991px) {
    background-image: url("./assets/images/explore-page/explore-banner-sm.png");
    // height: 100px;

    h6 {
      font-size: 20px;
      width: 75%;
    }
  }
  @media screen and (max-width: 576px) {
    h6 {
      font-size: 18px;
    }
  }
}

// This is search used in competition and explore page
.search-inputgroup {
  border-radius: $border-radius;
  overflow: hidden;
  border: 1px solid #dadce0;
  height: 42px;

  .p-inputtext {
    padding-left: 18px;
    padding-right: 18px;
    &:focus {
      box-shadow: none;
    }
  }

  @media screen and (max-width: 991px) {
    height: 35px;
  }
}

.search-icon-with-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  flex-shrink: 0;
}

.student-detail-popup {
  box-shadow: none;
  padding: 0 1rem;
  &.p-dialog {
    app-student-detail-popup,
    app-view-award-list,
    .p-dialog-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    .p-dialog-header {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
    }
    // .p-dialog-header-icons {
    //   background: none;
    // }

    @include media-breakpoint-down(sm) {
      .p-dialog-title {
        font-size: 18px;
      }
    }

    .p-dialog-content {
      padding: 0;
      overflow: hidden !important;
      &:last-of-type {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
    .p-dropdown-label {
      color: #000000;
    }
  }
}

// registration-ends-notification
.registration-ends {
  &-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 12px;
    background-color: rgba($color: #e54f6d, $alpha: 0.1);
    color: #222222;
    padding: 1rem;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &-heading {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0.5rem;
  }

  &-note {
    font-size: 14px;
    margin: 0;
    font-style: italic;
  }
  &-timer {
    flex-shrink: 0;
    padding: 10px 18px;
    border-radius: 50rem;
    background-color: #ffffff;
    box-shadow: $box-shadow;
  }
}

.resgitration-incomplete {
  &-notification {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border-radius: 12px;
    background-color: rgba($color: #44a4ff, $alpha: 0.1);
    color: #222222;
    padding: 1rem;
    font-size: 14px;
    margin-top: 1rem;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      text-align: center;

      .unfortunately-message {
        &+div {
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      } 
    }
  }
}

app-submissions {
  .card {
    --bs-card-border-radius: 10px;
    color: #222222;
    box-shadow: 0 2px 14px 0 rgba(#14162a, 0.06);
    &.active-round {
      border: 1px solid rgba($color: #bbd7f8, $alpha: 1);
    }

    &.finished-round {
      border: 1px solid rgba($color: #f0f0f0, $alpha: 1);
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 48px;
        left: -38px;
        width: 2px;
        height: calc(100% + 2px);
        background-color: #ddebff;
        display: none;
        @media (min-width: 992px) {
          display: block;
        }
      }
    }

    .submission-title {
      font-size: 18px;
      color: #222222;
      margin: 0;
      font-weight: 500;

      &.active {
        color: #2c81e9;
      }
    }

    .round-instructions {
      margin: 0;
      padding: 0;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .badge-attachments {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f1f2f4;
      font-size: 14px;
      width: 34px;
      height: 34px;
      color: #595959;
    }
  }

  @media screen and (max-width: 576px) {
    .card ul {
      font-size: 14px;
    }
  }
}

.final-submission-dialog {
  .p-dialog-content {
    padding: 0;
  }

  .p-dialog-header {
    padding: 1rem 1.5rem;
  }

  &.p-dialog {
    border-radius: 28px;
  }

  .submission-dialog-content {
    padding: 0 1.5rem;
    color: #222222;
    font-size: 1rem;

    p {
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .btn {
    min-height: 44px;
  }
  .btn-outline-cancel {
    color: #797979;
    font-size: 1rem;
    border: 1px solid #e0e0e0;

    &:hover,
    :active {
      background-color: darken($color: $white, $amount: 5%);
    }
  }
}

.live-badge,
.finished-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 0.5rem;
  width: 100px;
  border-radius: 50rem;
  font-weight: 500;
}

.live-badge {
  color: #ff362a;
  background-color: #fef5f5;
}

.finished-badge {
  background-color: rgba($color: #0cbd80, $alpha: 0.1);
  color: #0cbd80;
  width: 190px;
}

.attachment {
  &-cards {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }

  &-card {
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba($color: #000000, $alpha: 0.13);
    overflow: hidden;

    figure {
      height: 140px;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &-title {
    color: #222222;
    font-size: 14px;
    font-weight: 500;
    padding: 14px 10px;
  }
}

.g-auth-btn-wrapper {
  display: flex;
  justify-content: center;
  > div {
    width: 290px;
  }
  iframe {
    width: auto !important;
  }
}

// Manage Admin Header titles
.manage-header-container {
  margin-bottom: 20px;
  flex-direction: column;

  h3 {
    color: #292d32;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0;
  }
  p {
    color: rgba($color: #292d32, $alpha: 0.6);
    font-weight: 500;
    font-size: 16px;
    margin-top: 0;
  }

  .form-control-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    gap: 20px;
  }

  label {
    white-space: nowrap;
    font-weight: 500;
    margin-bottom: 0;
  }

  .formField {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}

.text-endsin {
  font-size: 11px;
  color: #929a9e;
  font-weight: 500;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.09);
  margin: 1rem 0;

  @media screen and (max-width: 576px) {
    margin: 0.5rem 0;
  }
}

.competition-pdf-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  border: 1px solid rgba($color: #f0f0f0, $alpha: 1);
  box-shadow: 0 2px 10px rgba(#e9eef2, 0.4);
  max-width: 100%;

  &.disabled {
    border-color: #e1e6ea;
    background-color: #f9f9f9;
  }
  h6 {
    font-size: 15px;
    font-weight: 500;
    color: #30313d;
    margin: 0;
  }

  @media screen and (max-width: 576px) {
    padding: 0.725rem;
    gap: 0.5rem;

    h6 {
      font-size: 13px;
    }
  }
}

.qualified-banner {
  position: relative;
  padding: 1rem;
  padding-left: 95px;
  background: url("../src/assets/competitions/qualified-bg.png") no-repeat left
    top / cover;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  .qualified-badge {
    position: absolute;
    top: -6px;
    left: 30px;
  }

  p {
    font-size: 12px;
    color: #2c81e9;
    font-weight: 500;
  }

  .banner-heading-text {
    font-size: 18px;
    color: #3b4852;
    font-weight: 500;
  }

  .banner-sub-text {
    font-size: 14px;
    color: #4b555b;
  }
}

// Awards List used in modal & competitions
.awards {
  &-cards {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    background-color: #fff5e6;
    border-radius: 10px;
    padding: 1rem;

    &.view-awards-modal {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

      .awards-card {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  &-card {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba($color: #000000, $alpha: 0.13);
    overflow: hidden;
    padding: 1rem;
    text-align: center;

    h6 {
      font-size: 20px;
      font-weight: 500;
    }

    p {
      font-size: 1rem;
      font-weight: 500;
      color: #d87a35;
    }

    .awards-figure {
      margin: 0 auto;
      @media screen and (max-width: 576px) {
        max-width: 50%;
      }
    }
  }
}

.competition-start-indicator {
  position: relative;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  background-color: #2c81e9;
  border-radius: 50%;
  overflow: hidden;
  animation: pulse-shadow 1.5s infinite ease-out;

  &:before {
    content: "";
    position: absolute;
    top: 24%;
    left: 24%;
    width: 53%;
    height: 53%;
    background-color: white;
    border-radius: 50%;
  }
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(44, 129, 233, 0.12);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(44, 129, 233, 0.12);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(44, 129, 233, 0);
  }
}

.sample-question {
  &-content {
    * {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: #222222 !important;
      line-height: 1.8;
    }

    ol {
      padding-left: 1.5rem;
    }
    ul {
      padding-left: 2rem;
      margin-bottom: 0.5rem;
    }
  }

  &-title {
    font-weight: 500;
    font-size: 18px;
    color: #2c81e9;

    &.dark {
      color: #222222;
    }
  }

  &-count {
    background-color: #f1f2f4;
    color: #595959;
    font-size: 13px;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .files-cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 1rem;
    margin-top: 1.5rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 440px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .files-card {
      border-radius: 8px;
      box-shadow: 0 0 0.5rem 0px rgba(#cfcfcf, 0.75);
      padding: 12px;
      overflow: hidden;

      .title {
        font-size: 14px;
        font-weight: 500;
      }

      figure {
        width: 100%;
        height: 150px;
        border-radius: 8px;
        border: 1px solid #e9eef2;
        overflow: hidden;
        margin: 0;
        margin-top: 1rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.pdf-icon {
          padding: 0.5rem;
          text-align: center;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            height: 60%;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.right-placeholder-listing {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border-top: 1px solid #dee2e6;
    background-color: $white;
  }
  .p-accordion-header-text {
    color: $secondary;
    font-weight: 500;
    font-size: 14px;
  }
}

.text-radio-label {
  color: rgba($color: #1b1b1b, $alpha: 0.5);
  font-size: 14px;
  font-weight: 500;
}

.modal-custom-btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  background-color: #3247c6;
  border-radius: 50%;
  border: 0;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;

  &:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }
}

.primary-tab-menu {
  .p-tabview-nav-container {
    margin-bottom: 2.5rem;
  }
  .p-tabview-nav-content {
    max-width: 800px;
    margin: 0 auto;
  }
  .p-tabview-nav {
    display: flex;
    justify-content: center;
    background-color: transparent;
    overflow-x: auto;
    li {
      white-space: nowrap;
    }

    .p-highlight {
      .p-tabview-nav-link {
        background-color: transparent;
        &:before {
          background-color: $primary;
        }
      }
    }
  }

  .p-tabview-panels {
    background-color: transparent;
    padding: 0;
  }

  &.full-width-tabs {
    .p-tabview-nav-container {
      margin-bottom: 0;
    }
    .p-tabview-nav-content {
      max-width: initial;
    }
  }
  &.align-tabs-start {
    .p-tabview-nav {
      justify-content: flex-start;
    }
    .p-tabview-nav-link {
      height: 100%;
      display: flex;
      align-items: center;
      border-color: white !important;
      gap: 0.5rem;
      &:before {
        border-color: white;
      }
    }
  }
}

.essay-credit-details-card {
  background-color: $white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 3px 3px 0.25rem rgba(226, 236, 249, 0.45);

  .remaining-credits {
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    background-color: #fef5ff;
    gap: 0.825rem;

    p {
      color: #222222;
      font-weight: 500;
      font-size: 1rem;
      margin: 0;
    }

    span {
      color: #e54f6d;
    }

    .credit-text {
      font-size: 22px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    li {
      color: #595959;
      margin-bottom: 0.5rem;
      &:first-child {
        font-weight: 600;
        margin-bottom: 1rem;
      }
    }

    &.activity-list {
      margin-top: 1rem;
    }
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    .remaining-credits {
      flex-direction: column;
      justify-content: center;
    }

    ul {
      li {
        margin-bottom: 0.5rem;
        &:first-child {
          margin-bottom: 1rem;
        }
      }
      &.activity-list {
        margin-top: 0;
        border-right: 1px solid #e1e6ea;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .remaining-credits {
      padding: 1rem;
    }

    .btn {
      width: 100%;
    }
  }
}

.package-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .card {
    border-radius: 1rem;
    overflow: hidden;
    &-header {
      padding: 2rem;

      .card-subtitle {
        font-size: 22px;
        color: #222222;
        font-weight: 600;
      }
    }

    &:first-child {
      .card-header {
        background-color: #fffbf8;
      }

      .package-title {
        color: #feb67c;
      }

      .btn-package {
        background-color: #feb67c;
        color: $white;

        &:hover {
          background-color: darken(#feb67c, 10%);
        }
      }
    }
    &:nth-child(2) {
      .card-header {
        background-color: #f2fefe;
      }

      .package-title {
        color: #08d8d8;
      }

      .btn-package {
        background-color: #08d8d8;
        color: $white;

        &:hover {
          background-color: darken(#08d8d8, 10%);
        }
      }
    }
    &:nth-child(3) {
      .card-header {
        background-color: #e6f4fe;
      }

      .package-title {
        color: #4298d2;
      }

      .btn-package {
        background-color: #4298d2;
        color: $white;

        &:hover {
          background-color: darken(#4298d2, 10%);
        }
      }
    }
  }
}

.secondary-header {
  border: 1px solid #f1f1f1;
  @media (min-width: 992px) {
    border: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }
}

.select-review-control {
  &:checked {
    + .select-review-type-card {
      border: 1px solid #c7cfff;
      background-color: #edf0ff;
    }
  }
}

.select-review-type-card {
  max-width: 380px;
  width: 100%;
  box-shadow: $box-shadow;
  color: #3c4852;
  cursor: pointer;
  transition: 0.2s all;
  border-radius: 10px;

  p {
    margin-top: 1.5rem;
    font-weight: 500;
  }

  &:hover {
    // border: 1px solid #c7cfff;
    background-color: #dfe4ff;
  }

  @media (max-width: 576px) {
    max-width: 100%;
  }
}

.essay-form-steps {
  max-width: 700px;
  width: 100%;
  color: #3c4852;
  padding: 0 1rem;

  .essay-form-subtitle {
    color: #4154ca;
    font-size: 14px;
    font-weight: 500;
  }

  .card {
    border-radius: 10px;
    color: #3c4852;
  }

  .essay-upload {
    border: 1px dashed #cccccc;
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #30313d;
    border-radius: 10px;
  }

  .border-bottom {
    border-color: #e8e8e8 !important;
  }

  .essay-form-title {
    font-size: 22px;
  }
  @media screen and (max-width: 991px) {
    .essay-form-title {
      font-size: 18px;

      &.subtitle {
        font-size: 1rem;
      }
    }
  }
}

.custom-type-select {
  input {
    &:checked {
      + .form-check-label {
        border: 1px solid #c7cfff;
        background-color: #edf0ff;
      }
    }
  }
  .form-check-label {
    display: inline-block;
    width: 100%;
    border: 1px solid #f0f0f0;
    box-shadow: $box-shadow;
    border-radius: 10px;
    padding: 1.5rem 1rem;
    color: #3c4852;
    transition: 0.2s all;
    cursor: pointer;

    &:hover {
      background-color: #dfe4ff;
    }
  }

  @media (max-width: 768px) {
    .form-check-label {
      padding: 0.75rem;
      font-size: 14px;
    }
  }
}

.manage-judges-modal {
  .p-dialog-header {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 0;
    z-index: 99;
  }

  .p-tabview-nav-container {
    padding: 1rem 1rem 0;
  }

  .p-dialog-content {
    padding: 0;
  }

  .p-tabview {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .p-tabview-nav {
      .p-tabview-nav-link {
        padding-bottom: 1.5rem;
      }
    }

    p-tabpanel,
    .p-tabview-panel {
      height: 100%;

      .p-datatable {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .p-datatable-wrapper {
          flex-grow: 1;
          overflow: auto;
        }

        .p-paginator {
          padding: 1rem;
        }
      }
    }
  }

  .p-tabview-panels {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
  }
}

.your-edits-card {
  border-radius: 1rem;
  box-shadow: $box-shadow;
  label {
    color: #797979;
    font-size: 12px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    li {
      color: #0c76f0;
      font-size: 14px;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
      &:not(:first-child) {
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: -14px;
          top: 0.5rem;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          background-color: #c9c9c9;
        }
      }
    }
  }

  .card {
    border: 1px solid #eeeeee;
    border-radius: 12px;
  }

  .instruction-title {
    color: #797979;
    font-size: 12px;
    font-weight: 500;
  }

  textarea {
    background-color: $white;
    border: 1px solid #eeeeee;
    border-radius: 12px;
    padding: 1rem;

    &:-moz-placeholder {
      color: rgba($color: #222222, $alpha: 0.32);
    }
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  h6 {
    color: #5f6a7b;
  }

  &-item {
    position: relative;
    z-index: 1;
    &:not(:last-child) {
      padding-bottom: 1.5rem;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 9px;
        width: 1px;
        height: 100%;
        background-color: #4154ca;
        z-index: 1;
      }
    }
  }

  &-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: $white;
    font-size: 10px;
    z-index: 2;

    &.completed {
      background-color: #4154ca;
    }

    &.in-progress {
      background-color: #8d9bb1;
    }
  }
}

// Judges Portal
// HEader Dropdown in judges portal
.judge-competition-dropdown {
  .p-dropdown {
    @media screen and (max-width: 576px) {
      width: 180px;
      .p-dropdown-label {
        display: block;
      }
    }
  }
}
// settings menu in judges portal
app-settings {
  .p-inputtext {
    background-color: $white;
    border-radius: 6px;
    border: 1px solid #e2e3e6;
    padding: 0.5rem;
    font-size: 14px;
    color: rgba($color: #182a4e, $alpha: 0.7);
  }
}
.judges-container {
  flex-grow: 1;
  background-color: $white;
  border-radius: 12px;
  box-shadow: $box-shadow-sm;
  overflow: hidden;

  .primary-tab-menu {
    height: 100%;

    .p-tabview {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .p-tabview-nav-container {
      flex-shrink: 0;
    }
    .p-tabview-panels {
      overflow: auto;
    }

    .p-tabview-nav {
      .p-highlight {
        .p-tabview-nav-link {
          &:before {
            background-color: $judge-primary;
          }
        }
      }
    }
  }

  .p-datatable-table {
    .p-datatable-thead {
      th {
        padding: 0.5rem;
        color: #44546f !important;
        font-size: 14px;
        font-weight: 500;
        background-color: $white !important;
      }
    }
  }
}

.sub-filter {
  &.p-overlaypanel {
    box-shadow: none;
    width: 320px;
    @media screen and (max-width: 576px) {
      width: 83vw;
    }

    &:before {
      content: none;
    }
  }
}

.lock-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 2;
  background-color: $white;
  color: #91a1bc;
  border: 1px solid #cad5e9;

  .icon {
    font-size: 10px;
  }
}

.filter {
  &-overlay {
    color: #44546f;
    font-size: 14px;
    background-color: $white;
    box-shadow: $box-shadow-sm;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-weight: 500;
    overflow: hidden;
  }

  &-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0.725rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.725rem;
    font-size: 12px;

    .filter-item {
      display: flex;
      flex-direction: column;
      gap: 0.725rem;

      .p-inputtext {
        background-color: $white;
        border-radius: 6px;
        border: 1px solid #e2e3e6;
        padding: 0.5rem;
        font-size: 14px;
        color: rgba($color: #182a4e, $alpha: 0.7);
      }
    }

    .btn-asc,
    .btn-desc,
    .btn-judge-primary,
    .btn-cancel {
      border-radius: 6px;
      // padding: 0.5rem 1rem;
      font-weight: 500;
      font-size: 14px;
    }

    .btn-asc {
      background-color: #ffffff;
      color: rgba($color: #182a4e, $alpha: 0.7);
      border: 1px solid #f1f2f4;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($color: #f1f2f4, $amount: 1.5%) !important;
        color: darken($color: #182a4e, $amount: 1.5%) !important;
        border-color: transparent !important;
      }
      &.active {
        background-color: #f1f2f4;
        border-color: transparent !important;
      }
    }

    .btn-desc {
      background-color: #ffffff;
      color: rgba($color: #0055cc, $alpha: 0.7);
      border: 1px solid #e9f2ff;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($color: #e9f2ff, $amount: 1.5%) !important;
        color: darken($color: #0055cc, $amount: 1.5%) !important;
        border-color: transparent !important;
      }
      &.active {
        background-color: #e9f2ff;
        border-color: transparent !important;
      }
    }

    .btn-cancel {
      background-color: #ffffff;
      color: rgba($color: #222222, $alpha: 0.7);
      border-color: #e4e4e4 !important;
      &:hover,
      &:active,
      &:focus {
        background-color: darken($color: #ffffff, $amount: 1.5%) !important;
        color: darken($color: #222222, $amount: 1.5%) !important;
        border-color: #e4e4e4 !important;
      }
    }
  }
}

.view-details-table {
  &.table {
    --bs-table-color: #182a4e;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      color: #44546f;
    }
    td {
      border: 1px solid #dddfe5;
    }

    tbody {
      td {
        &:last-child {
          width: 150px;
        }

        .custom-ternary.p-light .p-button {
          padding: 6px 14px;
        }
      }
    }
  }
}

// badge for submission modal
.current-stat-badge,
.judges-modal-header {
  background-color: rgba($color: #0b66e4, $alpha: 0.06);
  border: 1px solid rgba($color: #0b66e4, $alpha: 0.26);
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  padding: 0.5rem;
  color: #0b66e4;
  width: 100%;
}

.current-stat-badge {
  max-width: 300px;
}

// judges-view-details-popup
.review-submission-modal {
  .p-dialog-content {
    padding: 0;
  }
}

.judges-view-details-popup .p-dialog,
.review-submission-modal.p-dialog {
  &.p-dialog-maximized {
    border-radius: 0;
  }
  .p-dialog-header-icons {
    background-color: $white;
  }
}

.review-submission-modal.p-dialog,
.judges-view-details-popup.p-dialog {
  @media screen and (max-width: 768px) {
    border-radius: 0;
    .p-dialog-header {
      padding: 1rem;
    }
    .p-dialog-content:last-of-type,
    .p-dialog-header {
      border-radius: 0 !important;
    }

    .p-dialog-content {
      padding: 0;
    }
  }
}

.refund-modal {
  p-inputnumber,
  .p-inputnumber {
    width: 100%;
  }
}

.judges-modal-header {
  color: #44546f;
  h6 {
    color: #0b66e4;
    font-size: 16px;
    font-weight: 500;
  }
  .label {
    color: #44546f;
    font-size: 12px;
  }
}

// Awards
.card {
  &.awards-card {
    --bs-card-border-width: 0;
    border-radius: 14px;
    color: $white;
    padding: 0;
    height: 100%;
    &.primary {
      background: linear-gradient(to right, #5ea7ee 0%, #9ec7f5 100%);
    }
    &.secondary {
      background: linear-gradient(to right, #f69351 0%, #e9ac7c 100%);
    }
    .card-body {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      padding: 1rem;

      .card-title {
        font-size: 20px;
      }

      p {
        color: rgba($color: $white, $alpha: 0.8);
      }

      figure {
        width: 90px;
        background-color: $white;
        padding: 0.5rem;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &.highlight-payment-card {
    border: 1px solid #0cbd80;
  }

  &.invite-card-border {
    border: 1px solid #e1e6ea;
  }

  &.highlighted-step-card {
    border-radius: 6px;
    overflow: hidden;

    .status-img {
      width: 24px;
      height: 24px;
    }

    &.incomplete {
      .header {
        background-color: #fee8ec;
      }
    }

    &.complete {
      .header {
        background-color: rgba($color: #00b095, $alpha: 0.13);
      }
    }
  }
}
.unfortunately-message {
  font-weight: 500;
  span {
    text-decoration: underline;
    color: #2494ff;
  }
}

.submission-submitted {
  background-color: rgba($color: #44a4ff, $alpha: 0.1);
  border-radius: 12px;
  padding: 1rem;
  color: #222222;
  font-size: 16px;
  font-weight: 500;

  figure {
    width: 70px;
  }
}

// primeng multiselect
.p-multiselect-panel {
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($primary, 0.15);
  .p-multiselect-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: $white;
  }
  .p-multiselect-items .p-multiselect-item {
    transition: 0.4s all;
    color: #4b4b4b;
    font-weight: 500;
    font-size: 14px;
    &:not(.p-highlight) {
      &:not(.p-disabled) {
        &.p-focus {
          background-color: transparent;
        }
        &:hover {
          background-color: #f0f5ff;
        }
      }
    }
  }
}

.pointer-none {
  color: grey;
  pointer-events: none; /* Disables clicking and other pointer events */
  cursor: not-allowed;
}

// Card view against table
.card {
  &.mobile-card {
    font-weight: 500;
    border-radius: 6px;
    .card-label {
      margin-bottom: 0.5rem;
      font-size: 12px;
      color: #797979;
    }
    .card-desc {
      font-size: 14px;
      color: #222222;
    }
    .card-footer {
      --bs-card-cap-padding-y: 1rem;
      background-color: $white;
      border-top: 1px solid #e9eef2;
    }

    &.highlight-review-card {
      background-color: #f2f0ff;
      border-left: 5px solid #8270db;
      .btn-light {
        background-color: $white !important;
      }
      .card-footer {
        background-color: #f2f0ff;
      }

      .highlight-desc {
        color: #8270db;
      }
    }

    @media screen and (max-width: 560px) {
      .btn-white-download {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
  }

  &.review-submission-modal-card {
    font-size: 14px;
    .card-header {
      background-color: #f5f6f7;
      color: #44546f;
    }

    .card-body {
      label {
        display: inline-block;
        font-weight: $font-weight-medium;
        color: #222222;
        margin-bottom: 0.5rem;
      }

      .form-check {
        display: flex;
        align-items: center;
        gap: 0.725rem;

        &-input {
          border-color: #ced0d3;
          margin-top: 0;
        }

        &-label {
          margin-bottom: 0;
          color: #44546f;
          font-size: 14px;
        }
      }
    }
  }
}

// Share Page Styles
.share-card {
  color: #222222 !important;
  border: 1px solid rgba($color: #000000, $alpha: 0.12);
  border-radius: 12px;
  .card-title {
    h6 {
      font-size: 18px;
      font-weight: 500;
    }
  }

  p {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .copy-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 12px;
    border-radius: 12px;
    background-color: #f3f8ff;
    margin-top: 0.725rem;
    cursor: pointer;
    .clipboard-text {
      color: #0f6fff;
      font-size: 14px;
    }
    span {
      display: flex;
      align-items: center;
    }
  }

  &.share-card-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    border-radius: 0;
  }

  &.certificate-card {
    background-color: #142a5e;
    color: #ffffff !important;

    .certification-img {
      width: 44px;
      height: 44px;
    }

    .btn-white-download {
      color: #424242;
      font-size: 14px;
    }

    .share-items {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
}

.share-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.45);
  z-index: 999;

  & + .share-card {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    border: 0;
    border-radius: 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    max-height: 100%;
    overflow: hidden;
  }

  @media screen and (min-width: 992px) {
    & + .share-card {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 12px;
      width: 100%;
      max-width: 800px;
      margin: 0 auto;
    }
  }
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
  padding: 0.5rem;
}

// End: Share Page Styles

.revenue-controls {
  @media screen and (max-width: 991px) {
    app-search {
      .search-container {
        max-width: 100%;
      }
    }
  }
}

// New Admin Changes
app-comp-details {
  .p-card {
    border: 0;
  }

  app-algoed-email-templates {
    .p-selectbutton {
      border: 0;
      padding: 0;
      display: flex;
      gap: 1rem;
    }

    .p-selectbutton .p-button {
      border: 1px solid #e7e7e7;
      color: #797979;
      border-radius: $border-radius;
      &.p-highlight {
        color: $white;
        border-radius: $border-radius !important;
      }
    }
  }

  .bordered-timer {
    .timer-section {
      max-width: 250px;
      border: 1px solid rgba($color: #000000, $alpha: 0.1);
      border-radius: $border-radius;
      padding: 0.725rem !important;
      margin-top: 0.5rem;
    }
  }
}

.send-email-modal {
  .p-inputtext,
  .p-dropdown .p-dropdown-trigger {
    background-color: #f1f3f4;
    color: #222222;
    border-radius: $border-radius;
    font-weight: 500;
  }
  .p-dropdown {
    border-radius: $border-radius;
  }

  .timezone-dropdown {
    .p-inputtext {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-dropdown-trigger {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.email-preview-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.725rem 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.11);
  }

  &:first-child {
    padding-top: 0;
  }

  .p-inputtext {
    background-color: white;
    border-radius: 0;
    padding: 0;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  .p-dropdown {
    &:focus {
      box-shadow: none;
    }
    .p-dropdown-trigger {
      background-color: $white;
    }
  }

  .email-content-area {
    min-height: 250px;
    padding: 0.725rem 0;
    flex-grow: 1;
  }

  .form-label {
    color: #747474;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .email-chips {
    .p-input-wrapper {
      border-radius: $border-radius;
      .p-chips-multiple-container {
        background-color: $white;
        padding: 0;
        box-shadow: none;

        .p-chips-token {
          border-radius: $border-radius;
          background-color: $white;
          color: #222222;
          border: 1px solid rgba($color: #000000, $alpha: 0.25);
        }

        .p-chips-input-token {
          box-shadow: none !important;
          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

// Ngx Editor Reset
.NgxEditor {
  --ngx-editor-border-color: transparent;
  min-height: 280px;
}

.email-content-area,
.NgxEditor__Content {
  * {
    margin: 0;
    word-break: break-all;
    max-width: 100%;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  ul {
    padding-left: revert;
  }
}

// Schedule email
.email-form-control-wrapper {
  p-dropdown {
    width: 100%;
  }
  .p-dropdown,
  .p-inputtext {
    background-color: $white;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// Email Test Footer
.email-footer-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
  padding: 1.5rem;
  background-color: #f0f4f7;
  color: #665566;
  font-size: 14px;

  .email-footer-algoed-logo {
    width: 90px;
  }

  p {
    a {
      color: #2d81f7;
      text-decoration: none;
    }
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    a {
      color: #2d81f7;
      text-decoration: underline;
      position: relative;
      &:not(:first-child) {
        &:before {
          content: "";
          position: absolute;
          top: 8px;
          left: -10px;
          display: inline-block;
          width: 0.25rem;
          height: 0.25rem;
          border-radius: 50%;
          background-color: #9cb1c1;
        }
      }
    }
  }

  .algoed-title {
    color: #9aafbf;
    margin: 0;
    text-align: center;
  }
}

.generic-tabs {
  .p-tabview .p-tabview-nav {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;
  }
}

// Explore Page Styles
$statuses: (
  upcoming: (
    background: rgba(#78c0ff, 0.1),
    border: #78c0ff,
    color: #1b95ff,
  ),
  closed-registration: (
    background: rgba(#ededed, 0.1),
    border: #d8d8d8,
    color: #30313d,
  ),
  finished: (
    background: rgba(#802000, 0.1),
    border: #802000,
    color: #802000,
  ),
  late-registration: (
    background: rgba(#eb3b4d, 0.1),
    border: #ef4f5f,
    color: #eb3b4d,
  ),
  priority-registration: (
    background: rgba(#0aaa73, 0.1),
    border: #0aaa73,
    color: #0aaa73,
  ),
  regular-registration: (
    background: rgba(#2678eb, 0.1),
    border: #2678eb,
    color: #2678eb,
  ),
);

.explore-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.explore-competitions-cards {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .card {
    --bs-card-spacer-y: 0;
    --bs-card-spacer-x: 0;

    &-figure {
      width: 100%;
      max-width: 254px;
      height: 134px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      aspect-ratio: 16 / 9;

      @include media-breakpoint-down(xl) {
        max-width: 100%;
        height: auto;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-body {
      color: #3b4852;
      font-size: 13px;
      img {
        width: 12px;
        height: 12px;
      }

      .dot-separator {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #3b4852;
        flex-shrink: 0;
      }
    }
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: #30313d;
      margin: 0;
    }

    .competition-status {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 320px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      color: #30313d;

      @each $status, $styles in $statuses {
        &.#{$status} {
          background: map-get($styles, background);
          border-color: map-get($styles, border);
          color: map-get($styles, color);
        }
      }
    }
  }
}

// Explore Page Styles
$statuses: (
  upcoming: (
    background: rgba(#78c0ff, 0.1),
    border: #78c0ff,
    color: #1b95ff,
  ),
  closed-registration: (
    background: rgba(#ededed, 0.1),
    border: #d8d8d8,
    color: #30313d,
  ),
  finished: (
    background: rgba(#802000, 0.1),
    border: #802000,
    color: #802000,
  ),
  late-registration: (
    background: rgba(#eb3b4d, 0.1),
    border: #ef4f5f,
    color: #eb3b4d,
  ),
  priority-registration: (
    background: rgba(#0aaa73, 0.1),
    border: #0aaa73,
    color: #0aaa73,
  ),
  regular-registration: (
    background: rgba(#2678eb, 0.1),
    border: #2678eb,
    color: #2678eb,
  ),
);

.explore-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.explore-competitions-cards {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .card {
    --bs-card-spacer-y: 0;
    --bs-card-spacer-x: 0;

    &-figure {
      width: 100%;
      max-width: 254px;
      height: 134px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      aspect-ratio: 16 / 9;

      @include media-breakpoint-down(xl) {
        max-width: 100%;
        height: auto;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-body {
      color: #3b4852;
      font-size: 13px;
      img {
        width: 12px;
        height: 12px;
      }

      .dot-separator {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #3b4852;
        flex-shrink: 0;
      }
    }
    &-title {
      font-size: 18px;
      font-weight: 600;
      color: #30313d;
      margin: 0;
    }

    .competition-status {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      max-width: 320px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 4px 8px;
      font-size: 12px;
      color: #30313d;

      @each $status, $styles in $statuses {
        &.#{$status} {
          background: map-get($styles, background);
          border-color: map-get($styles, border);
          color: map-get($styles, color);
        }
      }
    }
  }
}

.admin-quiz-settings {
  p-inputNumber {
    width: 50px;
  }
}

app-quiz-settings {
  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    white-space: normal;
  }

  .p-multiselect-panel .p-multiselect-items {
    .p-multiselect-item {
      span {
        white-space: normal;
      }
    }
  }

  .p-multiselect .p-multiselect-label {
    font-size: 14px;
    padding: 5px 0.5rem;
  }
}

.card {
  &.quiz-navigator-card {
    .card-title {
      font-size: 18px;
      color: #222222;
    }

    .text-desc {
      color: #222222;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      padding: 4px;
      border-radius: $border-radius;
      font-size: 20px;

      &.files {
        background-color: #e0edff;
        color: $primary;
      }
      &.timer {
        background-color: #ffe7ec;
        color: $ternary;
      }
    }

    .quiz-navigator-footer {
      padding: 1rem;
      border-top: 1px solid rgba($color: #000000, $alpha: 0.09);
    }

    &.quiz-practice-round-card {
      --bs-card-border-color: #eaeef1;
      --bs-card-bg: #f7f8fa;
      box-shadow: none;

      .card-body {
        border: 0;
      }
    }
  }
}

app-leader-board {
  .btn,
  .p-button {
    &:disabled,
    &.disabled {
      background-color: #e5e5e5 !important;
      color: #ababab !important;
      border: none !important;
    }
  }
}

.disqualified-but-awarded-notification {
  padding: 1rem;
  @media screen and (max-width: 576px) {
    .btn {
      width: 100%;
    }
  }
}